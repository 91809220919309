import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import DeliveryUpdateForm from "../GiftSubscription/DeliveryUpdateForm";
import { isValidPhoneNumber } from "../../helpers/validator";

export function UpdateDeliveryDialog(props) {
  const isOpen = props.isOpen;
  const handleCloseDialog = props.handleOnCloseDeliveryDialog;
  const handleSave = props.handleSave;
  const delivery = props.delivery;

  const [phoneNumber, setPhoneNumber] = useState(
    props.delivery.gift_reciever_phone
  );
  const [address, setAddress] = useState(props.delivery.gift_reciever_address);
  const [personalMessage, setPersonalMessage] = useState(
    props.delivery.personal_message
  );

  const initialState = { valid: true, message: "" };

  const [phoneNumberState, setPhoneNumberState] = useState(initialState);

  const styles = {
    textField: { marginBottom: 20, width: 500 },
  };

  const [open, setOpen] = React.useState(isOpen);

  const handlePhoneChange = (event) => {
    if (validatePhoneNumber(event)) {
      setPhoneNumber(event.target.value);
    }
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handlePersonalMessageChange = (event) => {
    setPersonalMessage(event.target.value);
  };

  const handleClose = () => {
    setPhoneNumberState(initialState);
    handleCloseDialog();
  };

  const handleSaveChanges = () => {
    const modifiedDelivery = {
      ...delivery,
      gift_reciever_phone: phoneNumber,
      gift_reciever_address: address,
      personal_message: personalMessage,
    };

    handleSave(modifiedDelivery);
  };

  const validatePhoneNumber = (event) => {
    if (isValidPhoneNumber(event.target.value)) {
      setPhoneNumberState({ valid: true, message: "" });
      return true;
    } else {
      setPhoneNumberState({ valid: false, message: "Invalid phone number" });
      return false;
    }
  };

  useEffect(() => {
    setOpen(isOpen);
    return () => {
      setOpen(false);
    };
    // eslint-disable-next-line
  }, [props]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="select-flavour-dialog-title">
          <div style={{ display: "flex" }}>
            <div style={{ margin: "auto" }}>Update Delivery</div>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <DeliveryUpdateForm
            delivery={delivery}
            phoneNumberState={phoneNumberState}
            styles={styles}
            handlePhoneChange={handlePhoneChange}
            handleAddressChange={handleAddressChange}
            handlePersonalMessageChange={handlePersonalMessageChange}
            handleClose={handleClose}
            handleSaveChanges={handleSaveChanges}
          ></DeliveryUpdateForm>
        </DialogContent>
      </Dialog>
    </>
  );
}
