import React from 'react';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';

import './cart.styles.css';

const CartIcon = ({ numberOfItems, openCart }) => {
  return (
    <div className="cart-info" onClick={openCart}>
      <div className="cart-icon-badge">{numberOfItems}</div>
      <ShoppingCartTwoToneIcon className="cart-icon" />
    </div>
  );
};

export default CartIcon;
