import React, { useState } from "react";
import { IconButton, makeStyles, Popover } from "@material-ui/core";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";

const useStyles = makeStyles((theme) => ({
  popoverContent: {
    maxWidth: 400,
    padding: theme.spacing(2),
    whiteSpace: "break-spaces",
  },
}));

export default function InstructionModal({ instruction }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton disabled={!instruction} onClick={handleClick}>
        {instruction ? (
          <ChatBubbleIcon fontSize="small" />
        ) : (
          <ChatBubbleOutlineIcon fontSize="small" />
        )}
      </IconButton>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={classes.popoverContent}>{instruction}</div>
      </Popover>
    </div>
  );
}
