import React, { useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'
import './picklist-home.styles.css'
import logo from '../../assets/images/chaebanlogo.png'
import { ReactComponent as Loader } from '../../assets/loader.svg'

let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
    link = `http://localhost:4000/`;
} else {
    env = url.split('.')[0];
    sec = env.split('//')[1];
    if (sec === 'secure2') {
        link = `https://${sec}.chaebanicecream.com/public_api/`;
    } else if (sec === 'secure3') {
        link = `https://${sec}.chaebanicecream.com/public_api/`;
    } else {
        link = `http://localhost:4000/`;
    }
}

const PickListHome = () => {

    let { driverID } = useParams()
    const history = useHistory()
    const location = useLocation()
    const [driverName, setDriverName] = useState('')
    const [driverGroups, setDriverGroups] = useState([])

    useEffect(() => {
        getDriverGroups()
    }, [])

    const getDriverGroups = async () => {
        let driverGroup = await axios.get(`${link}driverGroup/${driverID}`)
        setDriverName(driverGroup.data.driverData.name)
        setDriverGroups(driverGroup.data.driverGroupData.recordset)

    }




    const handleSelectGroup = (gid, gname, picklistid) => {
        history.push({
            pathname: `${location.pathname}/lockroute`,
            state: {
                routeName: gname,
                routeID: gid,
                picklistID: picklistid,
                driverName
            }
        })
    }

    let homepageText = ''
    if (driverGroups.length == 0) {
        homepageText = "Seems like you don't have any routes for today!"
    }
    else if (driverGroups.length == 1) {
        homepageText = 'You have one route today. Click on the route to start delivering'

    } else {
        homepageText = `You have ${driverGroups.length} routes today. Pick a route to start delivering`
    }


    return (
        <div>
            {
                !driverName ?
                    <div className='loader-container'>
                        <Loader className="loader" />
                    </div> :
                    <div className='driver-homepage'>
                        <div className='logo-picklist'>
                            <img src={logo} alt='chaeban-logo' />
                        </div>
                        <div className='driver-text'>
                            <h2>Hello  {driverName} !!!</h2>
                            <span>{homepageText}</span>
                        </div>
                        <div className='driver-group-tiles'>
                            {
                                driverGroups.map(grp => {
                                   
                                    let start_date_text = grp['group_start_time'].split("T")[0]
                                    let start_time_text = grp['group_start_time'].split("T")[1].substring(0,5)
                                    
                                    return <button className='group-title-button' onClick={() => handleSelectGroup(grp['group_id'], grp['group_name'], grp['picklist_id'])} key={grp['group_id']} value={grp['group_id']}>
                                        {`${grp['group_name']} : ${start_date_text} : ${start_time_text}`}
                                    </button>
                                })
                            }


                        </div>
                    </div>
            }

        </div>
    )
}

export default PickListHome;