import React from 'react';
import { Link } from 'react-router-dom';

import './OrderItem.css';

const OrderItem = ({ customerId, order }) => {


  let scheduled_date = ''
  let scheduled_time = ''

  try {
    if (order.groupData[0] === 'Store') {
      scheduled_date = 'Store Pick Up'
      scheduled_time ='Store Hours'

    } else {
      scheduled_date = order.groupData.recordset[0].group_start_time.split("T")[0]
      scheduled_time = `${order.groupData.recordset[0].group_start_time.split("T")[1].slice(0,5)} to ${order.groupData.recordset[0].group_end_time.split("T")[1].slice(0,5)}`

    }
  }
  catch (err) {
    scheduled_date = 'Not scheduled yet'
    scheduled_time = 'Not scheduled yet'
  }

  return (
    <Link
      to={{
        pathname: `flavorSelection/${order.orderNumber}?customerId=${customerId}&otp=${order.otp}`,
        state: {
          customerId,
          currentMonth: order.currentMonth,
          isEditable: order.isEditableStatus === '1'
        },
      }}
      className="order-item layout--centered"
    >
      <div>
        Order {order.orderNumber} • {order.currentMonth}
      </div>
      <div>{order.orderTitle}</div>
      <div>Scheduled Delivery Date : {scheduled_date} </div>
      <div>Scheduled Time : {scheduled_time} </div>
    </Link>
  );
};

export default OrderItem;
