import React, { useEffect, useState } from "react";
import moment from "moment";
import CustomerUpdateModal from "./CustomerUpdateModal";
import LineItemsDialog from "./LineItemsDialog";
import {
  makeStyles,
  IconButton,
  Paper,
  Tabs,
  Tab,
  Tooltip,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import { DataGrid } from "@material-ui/data-grid";
import { useConman } from "../../services/useConman";

const tabs = ["failed", "blocked", "successful"];

const columns = [
  { field: "order_number", headerName: "Order", width: 90 },
  { field: "name", headerName: "Name", width: 170 },
  { field: "email", headerName: "Email", width: 220 },
  { field: "phone", headerName: "Phone", width: 150 },
  { field: "address", headerName: "Address", width: 420 },
  { field: "zip", headerName: "Zip", width: 90 },
  { field: "onfleet_message", headerName: "Reason", width: 250 },
  {
    field: "created_at",
    type: "date",
    headerName: "Created At",
    valueFormatter: ({ value }) => moment(value).format("DD-MM-YYYY HH:mm"),
    width: 170,
  },
];

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: "1rem",
  },
  paper: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttons: {
    padding: "0 1.5rem",
  },
  gridContainer: {
    height: 640,
    width: "100%",
  },
  root: {
    "& div.MuiDataGrid-colCellTitle": {
      fontWeight: 700,
    },
  },
}));

function Orders() {
  const [tab, setTab] = useState(0);
  const [status, setStatus] = useState("failed");
  const [selectedRows, setSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showLineItems, setShowLineItems] = useState(false);

  const conman = useConman();
  const classes = useStyles();

  useEffect(() => {
    conman.fetchOrders(status);
    // eslint-disable-next-line
  }, [status]);

  const handleChange = (e, val) => {
    setTab(val);
    setStatus(tabs[val]);
  };

  const handleSelectionChange = (rows) => {
    const selected = conman.payload.filter(
      (order) => rows.selectionModel.indexOf(order.order_id) > -1
    );
    setSelectedRows(selected);
  };

  const handleSubmit = async (data) => {
    await conman.updateOrder(data);
    setShowModal(false);
    conman.fetchOrders(status);
  };

  return (
    <div className="orders">
      <LineItemsDialog
        show={showLineItems}
        onClose={() => setShowLineItems(false)}
        order={selectedRows[0] || null}
      />
      <CustomerUpdateModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={handleSubmit}
        order={selectedRows[0] || null}
      />
      <Typography
        component="h6"
        variant="h6"
        className={classes.heading}
        color="inherit"
        noWrap
      >
        Orders
      </Typography>
      <Paper className={classes.paper} square>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label={tabs[0]} />
          <Tab label={tabs[1]} />
          <Tab label={tabs[2]} />
        </Tabs>
        {status !== "success" && (
          <div className={classes.buttons}>
            <Tooltip title="View Line Items">
              <span>
                <IconButton
                  disabled={selectedRows.length !== 1}
                  onClick={() => setShowLineItems(true)}
                >
                  <ShoppingBasketIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Update & Submit to Onfleet">
              <span>
                <IconButton
                  disabled={selectedRows.length !== 1}
                  onClick={() => setShowModal(true)}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        )}
      </Paper>
      <Paper className={classes.gridContainer}>
        <DataGrid
          rows={
            conman.status === "loaded"
              ? conman.payload.map((o) => ({
                  id: o.order_id,
                  ...o,
                }))
              : []
          }
          loading={conman.status === "loading"}
          columns={columns}
          pageSize={10}
          className={classes.root}
          onSelectionModelChange={handleSelectionChange}
        />
      </Paper>
    </div>
  );
}

export default Orders;
