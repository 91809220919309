import React from "react";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import CheckedBoxIcon from "@material-ui/icons/CheckCircle";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import defaultProductImage from "./../../assets/images/defaultProductImage.png";
import { resizeImg } from '../../helpers/resize-img';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    marginBottom: "3em",
    width: "100%",
    [theme.breakpoints.up("415")]: {
      width: "50%",
    },
    [theme.breakpoints.up("1024")]: {
      width: "33.3%",
    },
  },
  image: {
    width: "calc(100% - 3rem)",
  },
  checkBox: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  titleBar: {
    backgroundColor: "#f8faf7",
    color: "#000",
    fontSize: 12,
    margin: "0.75rem 0.75rem 0 0",
    padding: "5px",
    textAlign: "center",
  },
}));

export default function FlavourGridItem({
  flavour,
  flavour_sku,
  onClick,
  onSelect,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img
        className={classes.image}
        src={flavour.image ? resizeImg(flavour.image.src) : defaultProductImage}
        alt={flavour.title}
        onClick={onClick}
      />
      <IconButton className={classes.checkBox}>
        {flavour_sku === flavour.variants[0].sku ? (
          <CheckedBoxIcon onClick={onSelect} />
        ) : (
          <Tooltip title="Click to select" placement="top-start">
            <CheckBoxOutlineBlankIcon onClick={onSelect} />
          </Tooltip>
        )}
      </IconButton>
      <div className={classes.titleBar} onClick={onClick}>
        {flavour.title}
      </div>
    </div>
  );
}
