import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import logo from "./../../assets/images/chaebanlogo.png";
import UserAvatar from "./../User/Avatar";
import { Redirect } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BarChartIcon from "@material-ui/icons/BarChart";
import LayersIcon from "@material-ui/icons/Layers";
import ConManIcon from "@material-ui/icons/SettingsInputComponent";
import GiftCardIcon from "@material-ui/icons/CardGiftcard";
import SideMenuRenderer from "../SideMenuRenderer/SideMenuRenderer";
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "#6A9598",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 8px",
    cursor: "pointer",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    border: 1,
    height: "85vh",
  },
  logoHeader: {
    fontWeight: "bold",
    marginLeft: 15,
    fontSize: 16,
  },
  logo: {
    height: 150,
    width: 250,
    margin: "auto",
  },
  pageContainer: {
    height: "90vh",
    margin: "10px",
    padding: "10px",
  },
}));

export default function Dashboard({ userContext, onLogout }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleLogout = () => {
    userContext.isLoggedIn = false;
    onLogout();
  };

  if (!userContext || !userContext.isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          ></IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>
          <IconButton color="inherit">
            <UserAvatar
              userContext={userContext}
              onLogout={handleLogout}
            ></UserAvatar>
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <Link
          to="/"
          className={classes.toolbarIcon}
          style={{ marginBottom: 40, marginTop: 10 }}
        >
          <img className={classes.logo} src={logo} alt="Chaeban" />
        </Link>
        <Divider />
        <List>
          <ListItem button component={Link} to="/gift-subscriptions">
            <ListItemIcon>
              <GiftCardIcon />
            </ListItemIcon>
            <ListItemText
              id="gift_subscriptions"
              primary="Gift Subscriptions"
            />
          </ListItem>
          <ListItem button component={Link} to="/products">
            <ListItemIcon>
              <LayersIcon />
            </ListItemIcon>
            <ListItemText primary="Products" />
          </ListItem>
          <ListItem button component={Link} to="/conman">
            <ListItemIcon>
              <ConManIcon />
            </ListItemIcon>
            <ListItemText primary="ConMan" />
          </ListItem>
          <ListItem button component={Link} to="/reports">
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItem>
          <ListItem button component={Link} to="/delivery">
            <ListItemIcon>
              <DeliveryDiningIcon />
            </ListItemIcon>
            <ListItemText primary="Delivery" />
          </ListItem>
          {/*<ListItem button component={Link} to="/unassigned">
            <ListItemIcon>
              <DeliveryDiningIcon />
            </ListItemIcon>
            <ListItemText primary="Unassigned" />
      </ListItem>*/}
        </List>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.pageContainer}>
          <SideMenuRenderer />
        </div>
      </main>
    </div>
  );
}
