import './shopify-product.styles.css';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import React, { useEffect, useState } from 'react';
import AlertDialog from '../../../Dialogs/AlertDialog';
import Link from '@material-ui/core/Link';
import { Alert } from '@mui/material';

const Product = ({ product, client, addVariantToCart }) => {
  const [image, setImage] = useState('');
  const [selectedVariantQuantity, setSelectedVariantQuantity] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    findImage();
  });

  const findImage = () => {
    try {
      setImage(product.ImageSource);
    } catch {
      setImage(
        'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Ice_cream_with_whipped_cream%2C_chocolate_syrup%2C_and_a_wafer_%28cropped%29.jpg/1200px-Ice_cream_with_whipped_cream%2C_chocolate_syrup%2C_and_a_wafer_%28cropped%29.jpg'
      );
    }
  };

  const handleQuantityChange = ({ target: { value } }) => {
    setSelectedVariantQuantity(value);
  };

  const handleInfoClick = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  let variant = product.Variants;
  let variantQuantity = selectedVariantQuantity || 1;

  return (
    <div className="Product">
      <AlertDialog
        open={isOpen}
        title={product.Title}
        message={product.Description}
        handleCloseDialog={handleCloseDialog}
      ></AlertDialog>
      <img src={image} alt="icecream-image" />
      <h5 className="Product__title">{product.Title}</h5>
      <div className="product-info">
        <Link
          className="info"
          component="button"
          underline="always"
          onClick={handleInfoClick}
        >
          INFO
        </Link>
        <div className="add-to-cart-button">
          <button
            className="Product__buy"
            onClick={() => addVariantToCart(variant.id, variantQuantity)}
          >
            Add to Cart
          </button>
        </div>
        <span className="Product__price">${variant.price.amount}</span>
        <div className="product-footer">
          <label className="Product__option">
            <input
              className="qty-input"
              min="1"
              type="number"
              defaultValue={variantQuantity}
              onChange={handleQuantityChange}
            ></input>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Product;
