import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import './AlertDialog.css';

export default function AlertDialog(props) {
  const [state, setState] = useState({ open: false, title: '', message: '' });
  const handleCloseDialog = props.handleCloseDialog;
  useEffect(() => {
    setState({
      open: props.open,
      title: props.title,
      message: props.message,
    });
    return () => {
      setState({ open: false });
    };
  }, [props]);

  const handleClose = () => {
    handleCloseDialog();
  };

  return (
    <div>
      <div>
        <Dialog
          open={state.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="title">{state.title}</div>
          <DialogContent>
            <div className="message">{state.message}</div>
          </DialogContent>
          <div className="actions">
            <button onClick={handleClose} className="button--primary">
              Ok
            </button>
          </div>
        </Dialog>
      </div>
    </div>
  );
}
