import React from 'react';
import './start-end-time.styles.css'
import DatePickerStart from '../DateTimePicker/date-time-picker-start.component';
import DatePickerEnd from '../DateTimePicker/date-time-picker-end.component';
import PickerDateTimeStart from '../DateTimePicker/new-date-picker.component.jsx'
import PickerDateTimeEnd from '../DateTimePicker/new-date-picker-end.component'

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CheckBox from '../CheckBox/check-box.component';


const StartEndTimePage = ({ selectStartDate, startdate, selectEndDate, enddate, handleRouteSubmit, label, handleCapacityEnter, routeCapacity, handleROuteActiveSelection , isRouteActive }) => {

    return(
    <div className='start-end-time-page'>
        <div className='datepicker-title'>
            <span>Route Start Date & Time </span>
           {/* <DatePickerStart className='date-picker' handleDateChangeStart={selectStartDate} startDate={startdate} />*/}
            <PickerDateTimeStart handleDateChangeStart={selectStartDate} startDate={startdate} />
        </div>
        <div className='datepicker-title'>
            <span>Route End Date & Time </span>
            {/*<DatePickerEnd handleDateChangeEnd={selectEndDate} endDate={enddate} />*/}
            <PickerDateTimeEnd handleDateChangeEnd={selectEndDate} endDate={enddate}/>
        </div>
        <div className='capacity-input'>
            <div className='capacity-input-text'>
                Route Capacity
            </div>
            <div className='capacity-input-field'>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '10ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            required
                            type="text"
                            id="capacity"
                            name="capacity"
                            label="capacity"
                            defaultValue={routeCapacity}
                            onChange={handleCapacityEnter}
                        />
                    </div>
                </Box>

            </div>
            <div>
                <CheckBox handleROuteActiveSelection={handleROuteActiveSelection} isRouteActive={isRouteActive}  />
            </div>
        </div>
        <Button className='zone-submit-button' type='submit' value="Submit" variant="contained" onClick={handleRouteSubmit}>{label}</Button>


    </div>
)}

export default StartEndTimePage;