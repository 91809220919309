

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import './add-zone.styles.css'
import axios from 'axios'
import { withRouter } from 'react-router';

let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
    link = `http://localhost:3000/`;
} else {
    env = url.split('.')[0];
    sec = env.split('//')[1];
    if (sec === 'secure2') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else if (sec === 'secure3') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else {
        link = `http://localhost:3000/`;
    }
}

const AddZone = ({ history }) => {
    const [newZoneName, setNewZoneName] = useState('')
    const [newZoneDescription, setNewZoneDescription] = useState('')

    const handleZoneAdd = async () => {

        await axios.post(`${link}addZone`,
            {
                zoneName: newZoneName,
                zoneDescription: newZoneDescription
            }
        )
        history.push('/delivery')


    }

    const changeInputZoneName = (event) => {
        setNewZoneName(event.target.value)
    }

    const changeInputZoneDescription = (event) => {
        setNewZoneDescription(event.target.value)
    }


    return (
        <div className='add-zone-page'>
            <div className='add-zone-text'>
                Add a new zone
            </div>
            <div className='add-zone-inputs'>
                <Box
                    component="form"
                    sx={{
                        textAlign: 'center',
                        '& .MuiTextField-root': { m: 1, width: 3/4 },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            required
                            type="text"
                            id="username"
                            name="username"
                            label="Zone Name"
                            defaultValue=""
                            onChange={changeInputZoneName}
                        />
                        <TextField
                            required
                            type="text"
                            id="username"
                            name="username"
                            label="Zone Description"
                            defaultValue=""
                            onChange={changeInputZoneDescription}
                        />
                    </div>
                </Box>

            </div>
            <div className='submit-button'>
                <Button style={{ maxWidth: '175px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }} type='submit' value="Submit" variant="contained" onClick={handleZoneAdd}>Save Zone!!</Button>

            </div>

        </div>

    );
}

export default withRouter(AddZone);
