import React, { useState } from "react";
import {
  IconButton,
  Popover,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  makeStyles,
} from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterList";

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: "1rem",
  },
}));

export default function ColumnFilter({ label, onChange }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState("both");
  const classes = useStyles();

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    setState(e.target.value);
    onChange(e.target.value);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton size="small" onClick={handleClick}>
        <FilterIcon
          fontSize="small"
          color={state === "both" ? "inherit" : "secondary"}
        />
      </IconButton>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={state}
            onChange={handleChange}
          >
            <FormControlLabel value="both" control={<Radio />} label="Both" />
            <FormControlLabel value="true" control={<Radio />} label={label} />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label={`Not ${label}`}
            />
          </RadioGroup>
        </FormControl>
      </Popover>
    </div>
  );
}
