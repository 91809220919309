import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import {
  DASHBOARD_MESSAGES,
  SNACKBAR_VARIANT,
} from "../components/Core/Constants";

const ProductsContext = createContext(undefined);

function ProductsProvider({ children }) {
  const [products, setProducts] = useState({ status: "loading" });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const result = await axios.get("/public_api/products");
        setProducts({ status: "loaded", data: result.data });
      } catch (error) {
        setProducts({ status: "error", error });
        enqueueSnackbar(DASHBOARD_MESSAGES.Products.Error, {
          variant: SNACKBAR_VARIANT.Error,
        });
      }
    };

    fetchProducts();
  }, [enqueueSnackbar]);

  return (
    <ProductsContext.Provider value={products}>
      {children}
    </ProductsContext.Provider>
  );
}

function useProducts() {
  const context = useContext(ProductsContext);
  if (context === undefined) {
    throw new Error("useProducts must be used within ProductProvider");
  }

  return context;
}

export { ProductsProvider, useProducts };
