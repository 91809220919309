import React from "react";
import './zone-card-container.styles.css'
import ZoneCard from '../ZoneCard/zone-card.component'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';





const ZoneCardContainer = ({ ZoneImages, setUserSelectedZone, selectedZone, selectedZoneId, routesAvailable, handleSelectRoute, selectedRoute, customerAddress, isEditable, history, isFlavPickerOpen, deliveryHighlight }) => {

    return (
        <div className='zone-card-container' >
            <div className='delivery-topic'>
                <h1>Delivery Preference</h1>
            </div>
            <div className='address-bar'>
                <span>{customerAddress}</span>
            </div>
            {/*     <div className='map-button'>
                <Link to='/zonemap' target="_blank" rel="noopener noreferrer" >
                    <Button
                        style={{ 'textDecoration': 'underline', 'fontWeight': 'bold', color: 'var(--chaeban-primary)' }}> click here to see the MAP</Button>
                </Link>
    </div> */}
            <div className="delivery-zone-text">
                Upcoming routes in your neighbourhood :
            </div>
            <div className={`${deliveryHighlight ? "delivery-highligh" : ""}`}>
                {/* <div className={`zone-cards`} >
                    {
                        ZoneImages.map(({ zone_id, ...otherProps }) => {
                            return <ZoneCard key={zone_id} zone_id={zone_id} {...otherProps} selectedZoneId={selectedZoneId} setUserSelectedZone={setUserSelectedZone} selectedZone={selectedZone} isEditable={isEditable} />
                        })
                    }
                </div>*/ }

                {
                    ((selectedZoneId) && (selectedZoneId != 0)) ?
                        (isEditable == 1) ?
                            <div>
                                <div className={`zone-dropdown ${isFlavPickerOpen ? "picker-open" : ""}`}>
                                    <FormControl fullWidth
                                        sx={{
                                            // width: 'auto',
                                            // whiteSpace: "normal",
                                            // wordBreak: "break-all",

                                        }}>
                                        <InputLabel id="demo-simple-select-label">Select Route*</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedRoute}
                                            label="Select route"
                                            onChange={handleSelectRoute}
                                            sx={{
                                                borderRadius: '20px',
                                                color: 'var(--chaeban-primary)',
                                            }}
                                        >
                                            {
                                                routesAvailable.map(dropdownNumber => {
                                                    return <MenuItem sx={{
                                                        whiteSpace: "normal",
                                                        wordBreak: "break-all",
                                                    }} key={dropdownNumber['group_id']} value={dropdownNumber['group_id']}>{`Name: ${dropdownNumber['group_name']} Date: ${dropdownNumber['group_start_time'].split("T")[0]} Time: ${dropdownNumber['group_start_time'].split("T")[1].toString().slice(0, 5)} to ${dropdownNumber['group_end_time'].split("T")[1].toString().slice(0, 5)}`}</MenuItem>
                                                })
                                            }

                                            {
                                                <MenuItem
                                                    sx={{
                                                        whiteSpace: "normal",
                                                        wordBreak: "break-all",
                                                    }} value={-1}>None of the above, please contact me!</MenuItem>
                                            }
                                            {
                                                <MenuItem sx={{
                                                    whiteSpace: "normal",
                                                    wordBreak: "break-all",
                                                }} value={0}>Store_Pick_Up</MenuItem>
                                            }

                                        </Select>
                                    </FormControl>
                                </div>
                            </div> :
                            <div>
                                <div className='zone-dropdown'>
                                    <FormControl fullWidth disabled>
                                        <InputLabel id="demo-simple-select-label" className='abc'>Select Route*</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedRoute}
                                            label="Select route"
                                            onChange={handleSelectRoute}
                                            sx={{
                                                borderRadius: '20px',
                                                color: 'var(--chaeban-primary)'

                                            }}
                                        >
                                            {
                                                routesAvailable.map(dropdownNumber => {
                                                    return <MenuItem sx={{
                                                        whiteSpace: "normal",
                                                        wordBreak: "break-all",
                                                    }} value={dropdownNumber['group_id']}>{`${dropdownNumber['group_name']} ${dropdownNumber['group_start_time']} ${dropdownNumber['group_end_time']}`}</MenuItem>
                                                })
                                            }
                                            {
                                                <MenuItem sx={{
                                                    whiteSpace: "normal",
                                                    wordBreak: "break-all",
                                                }} value={-1}>None of the above, please contact me!</MenuItem>
                                            }
                                            {
                                                <MenuItem sx={{
                                                    whiteSpace: "normal",
                                                    wordBreak: "break-all",
                                                }} value={0}>Store_Pick_Up</MenuItem>
                                            }

                                        </Select>
                                    </FormControl>
                                </div>
                            </div>


                        :
                        <div>
                            <div className={`zone-dropdown ${isFlavPickerOpen ? "picker-open" : ""}`}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Route*</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedRoute}
                                        label="Select route"
                                        onChange={handleSelectRoute}
                                        sx={{
                                            borderRadius: '20px',
                                            color: 'var(--chaeban-primary)'
                                        }}
                                    >
                                        {/*
                                            routesAvailable.map(dropdownNumber => {
                                                return <MenuItem key={dropdownNumber['group_id']} value={dropdownNumber['group_id']}>{`Name: ${dropdownNumber['group_name']} Date: ${dropdownNumber['group_start_time'].split("T")[0]} Time: ${dropdownNumber['group_start_time'].split("T")[1].toString().slice(0, 5)} to ${dropdownNumber['group_end_time'].split("T")[1].toString().slice(0, 5)}`}</MenuItem>
                                            })
                                        */}

                                        {
                                            <MenuItem sx={{
                                                whiteSpace: "normal",
                                                wordBreak: "break-all",
                                                
                                            }} value={-1}>None of the above, please contact me!</MenuItem>
                                        }
                                        {
                                            <MenuItem sx={{
                                                whiteSpace: "normal",
                                                wordBreak: "break-all",
                                            }} value={0}>Store_Pick_Up</MenuItem>
                                        }

                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                }
            </div>

        </div >

    )
}

export default withRouter(ZoneCardContainer);