
import React from 'react';
import { DataGrid} from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import DriverAssignDropdown from '../DriverAssignDropdown/driver-assign-dropdown.component';
import axios from 'axios';
import CustomizedSnackbarsSuccess from '../../Snackbar/snackbar.component'
import CustomizedSnackbarsFail from '../../Snackbar/snackbar-fail.component'


import './orders-table.styles.css'

let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
    link = `http://localhost:3000/`;
} else {
    env = url.split('.')[0];
    sec = env.split('//')[1];
    if (sec === 'secure2') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else if (sec === 'secure3') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else {
        link = `http://localhost:3000/`;
    }
}


const columns = [
    { field: 'col1', headerName: ' GroupID', width: 130 },
    { field: 'col2', headerName: ' ZoneID', width: 130 },
    { field: 'col3', headerName: ' OrderNumber', width: 150 },
    { field: 'col4', headerName: 'CustomerName', width: 150 },
    { field: 'col5', headerName: 'LineItems', width: 500 },
    { field: 'col6', headerName: 'InOnfleet?', width: 150 },
    { field: 'col7', headerName: 'WorkerID', width: 150 },
    { field: 'col8', headerName: 'UpdateTime', width: 150 },
    { field: 'col9', headerName: 'IsEditable', width: 150 },
    { field: 'col10', headerName: 'DeliveryStatus', width: 150 },
    { field: 'col11', headerName: 'OnfleetID', width: 150 },


];




const OrdersTable = ({ zoneName, zoneId, ZoneData, selectedRoutes }) => {



    useEffect(() => {
        getOrderData(zoneId, selectedRoutes)

    }, [])

    const getOrderData = async (zoneId, selectedRoutes) => {
        try {
            const orderData = await axios.get(`${link}getOrderData/`, {
                params: {
                    zoneId: zoneId,
                    selectedRoutes: selectedRoutes
                }
            })



            let clubOrdersData = []
            let giftOrdersData = []


            for (let i = 0; i < orderData.data[0].length; i++) {
                for (let j = 0; j < orderData.data[0][i].recordset.length; j++) {
                    clubOrdersData.push(orderData.data[0][i].recordset[j])
                }
            }

            for (let i = 0; i < orderData.data[1].length; i++) {
                for (let j = 0; j < orderData.data[1][i].recordset.length; j++) {
                    giftOrdersData.push(orderData.data[1][i].recordset[j])
                }
            }

            setOrderInfo(clubOrdersData)
            setGiftInfo(giftOrdersData)

        }
        catch (err) {
            console.log(err)
        }

    }

    const [selectedOrders, setSelectedOrders] = useState([]);
    const [orderInfo, setOrderInfo] = useState([])
    const [giftInfo, setGiftInfo] = useState([])
    const [successAssignTask, setSuccessAsignTask] = useState(false)
    const [failAssignTask, setFailAssignTask] = useState(false)



    let rows_orders = [];
    let k = 100000;
    for (let i = 0; i < orderInfo.length; i++) {
        k++;
        let lineItemArray = []
        let onfleetPresent = Boolean(orderInfo[i].onfleet_id)
        try {
            for (let j = 0; j < JSON.parse(orderInfo[i].line_items).length; j++) {
                lineItemArray.push(JSON.parse(orderInfo[i].line_items)[j].title)

            }
        }
        catch (err) {
            console.log(err)
        }
        rows_orders.push(
            {
                id: k, col1: orderInfo[i]["group_id"], col2: orderInfo[i]["zone_id"], col3: orderInfo[i]["order_number"], col4: orderInfo[i]["name"], col5: lineItemArray, col6: onfleetPresent,
                col7: orderInfo[i]['Worker_Id'], col8: orderInfo[i]["updated_at"], col9: orderInfo[i]["is_editable"], col10: orderInfo[i]["Delivery_Status"], col11: orderInfo[i]["onfleet_id"], col12: orderInfo[i]["email"], col13: null, col14: orderInfo[i]["customer_id"]
            })
    }

    const rows_gifts = giftInfo.map((Data, i) => {
        let onfleetPresent = Boolean(Data["onfleet_id"])

        return (
            {
                id: i, col1: Data["group_id"], col2: Data["zone_id"], col3: Data["shopify_ordername"], col4: Data["gift_reciever_name"][0], col5: `${Data["flavour1_name"]} & ${Data["flavour2_name"]}`, col6: onfleetPresent,
                col7: Data['worker_id'], col8: Data["order_updated_time"], col9: Data["is_editable"], col10: Data["delivery_status"], col11: Data["onfleet_id"], col12: Data["gift_reciever_email"][0], col13: Data["delivery_id"], col14: Data["subscriber_id"]
            }
        )
    })

    const rows = [...rows_gifts, ...rows_orders]


    return (
        <div className='orders-table-page'>
            <div className='orders-table-text'>
                Selected Zone: {zoneName}
            </div>
            <div style={{ height: 500, width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            checkboxSelection
                            rows={rows}
                            columns={columns}
                            onSelectionModelChange={(ids) => {
                                const selectedIDs = new Set(ids);
                                const selectedRowData = rows.filter((row) =>
                                    selectedIDs.has(row.id)

                                );
                                setSelectedOrders(selectedRowData)
                            }}
                        />
                    </div>
                </div>
            </div>
            <DriverAssignDropdown zoneName={zoneName} selectedOrders={selectedOrders} ZoneData={ZoneData} setSuccessAsignTask={setSuccessAsignTask} setFailAssignTask={setFailAssignTask} selectedRoutes={selectedRoutes} />
            <CustomizedSnackbarsSuccess successAssignTask={successAssignTask} setSuccessAsignTask={setSuccessAsignTask} />
            <CustomizedSnackbarsFail failAssignTask={failAssignTask} setFailAssignTask={setFailAssignTask} />
        </div>

    );
}

export default withRouter(OrdersTable)