
import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios'

import './zone-select.styles.css'

let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
    link = `http://localhost:3000/`;
} else {
    env = url.split('.')[0];
    sec = env.split('//')[1];
    if (sec === 'secure2') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else if (sec === 'secure3') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else {
        link = `http://localhost:3000/`;
    }
}



const SelectZone = ({ handleSelectZone, zoneName, ZoneData, setZoneInfo }) => {

    useEffect(() => {
        getAllZoneData();

    }, []);


    const getAllZoneData = async () => {
        const zoneData = await axios.get(`${link}getZones`)
        setZoneInfo(zoneData.data.recordset)
    }


    return (
        <div className='zone-select-page'>
            <div className='zone-select-text'>
                Select a zone
            </div>
            <div className='zone-select-dropdown'>
                {
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Zone</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={zoneName}
                                label="Select Zone"
                                onChange={handleSelectZone}
                            >
                                {
                                    ZoneData.map((zone, i) => {
                                        return (
                                            <MenuItem key={i} value={zone}>{zone['zone_name']}</MenuItem>
                                        )

                                    })
                                }
                                <MenuItem value={'NULL'}>ZONE NOT SELECTED</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                }
            </div>



        </div>
    );
}


export default SelectZone;
