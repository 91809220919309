import React from "react";
import { Button, TextField } from "@material-ui/core";

export default class DeliveryUpdateForm extends React.Component {
  render() {
    return (
      <form noValidate autoComplete="off">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={this.props.styles.textField}>
            <TextField
              fullWidth
              id="phone-number"
              label="Phone Number"
              variant="outlined"
              inputProps={{
                maxLength: 10,
              }}
              error={!this.props.phoneNumberState.valid}
              helperText={this.props.phoneNumberState.message}
              defaultValue={this.props.delivery.gift_reciever_phone}
              onChange={this.props.handlePhoneChange}
            />
          </div>
          <div style={this.props.styles.textField}>
            <TextField
              fullWidth
              id="address"
              label="Address"
              variant="outlined"
              multiline
              rowsMax={2}
              defaultValue={this.props.delivery.gift_reciever_address}
              onChange={this.props.handleAddressChange}
            />
          </div>
          <div style={this.props.styles.textField}>
            <TextField
              fullWidth
              multiline
              rowsMax={2}
              id="personal-message"
              label="Personal Message"
              variant="outlined"
              defaultValue={this.props.delivery.personal_message}
              onChange={this.props.handlePersonalMessageChange}
            />
          </div>
          <div
            style={{
              marginTop: 15,
            }}
          >
            <Button
              style={{
                textTransform: "none",
              }}
              color="primary"
              variant="contained"
              disabled={!this.props.phoneNumberState.valid}
              onClick={this.props.handleSaveChanges}
            >
              Save Changes
            </Button>
            <Button
              style={{
                textTransform: "none",
                marginLeft: 10,
              }}
              variant="contained"
              onClick={this.props.handleClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    );
  }
}
