import React from "react";
import Background from '../../../assets/images/ZoneMap.png'
import './zone-map-image.styles.css'



const ZoneMapImage = () => (
    <img className='zone-map' alt='background' src={ Background } />
)


export default ZoneMapImage;