import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";

function LineItemsDialog({ show, order, onClose }) {
  let lineItems = [];
  if (order) {
    lineItems = JSON.parse(order.line_items);
  }

  const rows = lineItems.map((lineItem) => (
    <TableRow key={lineItem.id}>
      <TableCell>{lineItem.product_id}</TableCell>
      <TableCell>{lineItem.name}</TableCell>
      <TableCell align="right">{lineItem.quantity}</TableCell>
    </TableRow>
  ));

  return (
    <Dialog open={show} onClose={onClose}>
      <DialogTitle>Order Line Items</DialogTitle>
      <DialogContent>
        {order && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LineItemsDialog;
