import React, { useState } from "react";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import { ParentRow } from "./ParentRow";
import { EnhancedTableHead } from "./EnhancedTableHead";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  makeStyles,
} from "@material-ui/core";
import { CircularProgressLoading } from "../Progress/CircularProgressLoading";
import { stableSort, getComparator } from "../Core/Sorting";
import { useSubscriptions } from "../../services/useSubscriptions";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    width: "100%",
    tableLayout: "fixed",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableHeader: {
    fontWeight: "bold",
  },
  childRowIcon: {
    padding: 0,
    width: 10,
  },
  hidden: {
    visibility: "hidden",
  },
  invalidEmailFormat: {
    fontWeight: "bold",
    color: "red",
  },
}));

export default function GiftSubscriptions() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("created_date");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedMainRow, setSelectedMainRow] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const subscriptions = useSubscriptions();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSelectedData = (row) => {
    const isFlavourSelected =
      row.deliveries.filter((d) => !d.flavour1_sku || !d.flavour2_sku)
        .length === 0;

    return {
      id: row.subscriber_id,
      shopify_id: row.shopify_id,
      gifter_name: row.gifter_name,
      gifter_email: row.gifter_email,
      gift_reciever_email: row.gift_reciever_email,
      gift_reciever_name: row.gift_reciever_name,
      months: row.months,
      email_sent: row.email_sent,
      flavour_selected: isFlavourSelected,
      validEmailFormat: row.validEmailFormat,
      subscriber_id: row.subscriber_id,
    };
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedData = subscriptions.data.map((row) =>
        createSelectedData(row)
      );

      setSelectedMainRow([...newSelectedData.map((d) => d.id)]);
      setSelectedData([...newSelectedData]);
      return;
    }
    setSelectedMainRow([]);
    setSelectedData([]);
  };

  const handleMainRowClick = (row) => {
    const id = row.id;
    const index = selectedMainRow.indexOf(id);
    if (index === -1) {
      setSelectedMainRow((s) => [...s, id]);
      setSelectedData((data) => [...data, createSelectedData(row)]);
    } else {
      const newSelectMainRow = selectedMainRow.filter((rowId) => rowId !== id);
      setSelectedMainRow([...newSelectMainRow]);

      const newSelectedData = selectedData.filter((r) => r.id !== id);
      setSelectedData([...newSelectedData]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (term, field) => {
    subscriptions.searchSubs({ term, field });
    setPage(0);
  };

  const handleOrderDateSorting = () => {
    setOrder(order === "asc" ? "desc" : "asc");
  };

  const handleFilter = (key, val) => {
    subscriptions.filterSubs(key, val);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, subscriptions.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      {subscriptions.status === "loading" ? (
        <CircularProgressLoading />
      ) : subscriptions.status === "error" ? (
        <div>Error loading subscriptions</div>
      ) : (
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            numSelected={selectedMainRow.length}
            onSearch={handleSearch}
            selectedMainRow={selectedMainRow}
            selectedData={selectedData}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
              style={{ tableLayout: "fixed" }}
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selectedMainRow.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={subscriptions.data.length}
                handleOrderDateSorting={handleOrderDateSorting}
                onFilter={handleFilter}
              />

              <TableBody>
                {stableSort(subscriptions.data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <ParentRow
                        key={row.subscriber_id}
                        row={row}
                        handleMainRowClick={handleMainRowClick}
                        selectedMainRow={selectedMainRow}
                      />
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={subscriptions.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  );
}
