import React, { useState } from "react";
import FlavourGridItem from "./FlavourGridItem";
import FlavourInfoDialog from "./FlavourInfoDialog";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 600,
    display: "flex",
    flexWrap: "wrap",
  },
}));

export default function FlavourGrid({
  flavours,
  flavourSelected,
  flavour_sku,
}) {
  const [isProductInfoOpen, setIsProductInfoOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState({
    title: "",
    body_html: "",
  });

  const classes = useStyles();

  const handleFlavourClick = (flavour) => {
    setCurrentProduct(flavour);
    setIsProductInfoOpen(true);
  };

  const handleCloseProductDialog = () => {
    setIsProductInfoOpen(false);
  };

  const handleFlavourSelected = (flavour) => {
    flavourSelected(flavour);
  };

  return (
    <div className={classes.root}>
      <FlavourInfoDialog
        isOpen={isProductInfoOpen}
        handleOnCloseProductInfoDialog={handleCloseProductDialog}
        product={currentProduct}
      />
      <div className={classes.gridList}>
        {flavours.map((flavour) => (
          <FlavourGridItem
            key={flavour.id}
            flavour_sku={flavour_sku}
            flavour={flavour}
            onClick={() => handleFlavourClick(flavour)}
            onSelect={() => handleFlavourSelected(flavour)}
          />
        ))}
      </div>
    </div>
  );
}
