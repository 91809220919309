import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/styles";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: "#F0817C",
    backgroundColor: "#FDEDED",
  },
}));

export default function UserAvatar({ userContext, onLogout }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (event) => {
    onLogout(event);
    setAnchorEl(null);
    return <Redirect to="/login" />;
  };

  return (
    <div>
      <Avatar onClick={handleClick} className={classes.avatar}>
        {userContext.username.charAt(0).toUpperCase()}
      </Avatar>

      <Menu
        id="userMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disabled={true}>
          Profile
        </MenuItem>
        <MenuItem onClick={handleClose} disabled={true}>
          My account
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
}
