import React from "react";
import {
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";

import { useProducts } from "../../services/useProducts";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { stableSort, getComparator } from "../Core/Sorting";
import { CircularProgressLoading } from "../Progress/CircularProgressLoading";

export default function Products() {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("title");

  const products = useProducts();

  const TableHeader = withStyles({
    head: {
      fontSize: 14,
      fontWeight: "bold",
    },
  })(TableCell);

  const SortableHeader = withStyles({
    head: {
      fontSize: 14,
      fontWeight: "bold",
      cursor: "pointer",
    },
  })(TableCell);

  const handleNameSorting = () => {
    SetOrder();
    setOrderBy("title");
  };

  const SetOrder = () => {
    setOrder(order === "asc" ? "desc" : "asc");
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return products.status === "loading" ? (
    <CircularProgressLoading />
  ) : products.status === "error" ? (
    <div>Error loading Products</div>
  ) : (
    <React.Fragment>
      <Typography component="h6" variant="h6" color="inherit" noWrap>
        Products
      </Typography>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableHeader>SKU</TableHeader>
            <TableHeader>Id</TableHeader>
            <SortableHeader>
              <TableSortLabel
                active={orderBy === "title"}
                direction={orderBy === "title" ? order : "asc"}
                onClick={handleNameSorting}
              >
                Name
              </TableSortLabel>
            </SortableHeader>
            <TableHeader>Price</TableHeader>
            <TableHeader>Inventory (Qty)</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(products.data, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.variants[0].sku}</TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.variants[0].price}</TableCell>
                <TableCell>{row.variants[0].inventory_quantity}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={products.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
}
