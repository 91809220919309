import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { groupBy } from 'rxjs/internal/operators/groupBy';
import { useHistory } from 'react-router-dom';


import ChaebanLogo from './ChaebanLogo';
import OrderItem from './OrderItem';
import './OrderNumberSelect.css';

let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
  link = `http://localhost:4000/`;
} else {
  env = url.split('.')[0];
  sec = env.split('//')[1];

  if (sec === 'secure2') {
    link = `https://${sec}.chaebanicecream.com/public_api/`;
  } else if (sec === 'secure3') {
    link = `https://${sec}.chaebanicecream.com/public_api/`;
  } else {
    link = `http://localhost:4000/`;
  }

}

const partialOrderRegex = /Partial/;

export default function OrderNumberSelect(props) {
  const customerId = props.match.params.id;
  const history = useHistory()

  useEffect(() => {
    getOrderNumbers();
    // sessionStorage.clear();
  }, []);

  const [orders, setOrders] = useState([]);
  const [customerName, setCustomerName] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState(true);
  // const [isOTP, setIsOTP] = useState(false)


  const getOrderNumbers = async () => {
    // const orderInfo = await axios.get(`http://localhost:4000/getClubSubscriptionOrderNumbers/VTJGc2RHVmtYMStUMmlRZEVNNCtucHpJSGwzWGxBVGZ0Umk5d25EcHczWT0=`)
    // const orderInfo = await axios.get(`https://secure3.chaebanicecream.com/public_api/getClubSubscriptionOrderNumbers/VTJGc2RHVmtYMStUMmlRZEVNNCtucHpJSGwzWGxBVGZ0Umk5d25EcHczWT0=`)

    try {
      const orderInfo = await axios.get(`${link}getClubSubscriptionOrderNumbers/${customerId}`);


      if (orderInfo.data[0]) {
        setCustomerName(orderInfo.data[0].customerName);

      }
      setOrders(orderInfo.data.filter(isActiveOrder));
      setIsLoaded(true);
    }
    catch {
      setIsDataAvailable(false)
    }
  };


  const isActiveOrder = (order) =>
    order.Delivery_Status == null ||
    (partialOrderRegex.test(order.deliveryStatus) && order.isEditableStatus === '0');

  const showOrders = () => isLoaded && orders && orders.length > 0;

  const closePage = () => {
    // window.open("about:blank", "_self");
    // window.close();
    history.push({
      pathname: `/clubUpdateFlavours/flavorSelection/${customerId}/addon`,
      state: {
        subsription : 'Club',
        customerId
      }
    })
  }

  return (
    <div className="order-number-select layout--centered">
      <ChaebanLogo />
      {showOrders() ? (
        <>
          <h1 className="order-number-select__title">{customerName}'s Orders</h1>
          <p className="order-number-select__text">
            Click on order(s) below to change selections and/or select delivery date and time.<br />
            (If you do not see latest orders, please refresh page)

          </p>
          <div className="order-number-select__list">
            {orders.map((order, i) => (
              <OrderItem key={order.orderNumber} customerId={customerId} order={order} />
            ))}
          </div>
          <button className='close-page-button' onClick={closePage}>Add more products!</button>
        </>
      ) : isDataAvailable ? (!isLoaded ?
        (
          <p className="order-number-select__text">Loading your orders...</p>
        ) : (
          <p className="order-number-select__text">
            Whoops! It looks like you don't have any orders at this time.<br />
            (If you do not see latest orders, please refresh page)
          </p>
        )) : (
        <p className="order-number-select__text">
          Whoops! It looks like you don't have any orders at this time.<br />
          (If you do not see latest orders, please refresh page)
        </p>
      )}
    </div>
  );
}
