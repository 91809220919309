import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function CheckBox({ handleROuteActiveSelection, isRouteActive }) {
    return (
        isRouteActive ?
            <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked/>} onChange={handleROuteActiveSelection} label="Do you want to mark this group active?"  />
            </FormGroup> :
            <FormGroup>
                <FormControlLabel control={<Checkbox />} onChange={handleROuteActiveSelection} label="Do you want to mark this group active?"  />
            </FormGroup>
    );
}
