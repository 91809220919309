import React, { useEffect, useState } from "react";
import { Button, Container, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FlavourSelectButton from "./FlavourSelectButton";
import SelectFlavourDialog from "./SelectFlavourDialog";
import { useFlavourSelection } from "../../services/useFlavourSelection";
import { CircularProgressBySize } from "../Progress/CircularProgressBySize";
import logo from "./../../assets/images/chaebanlogo.png";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  logo: {
    height: 160,
    width: 250,
  },
  container: {
    width: "100%",
    maxWidth: "800px",
    fontFamily: "'Roboto', Helvetica, Arial, sans-serif",
  },
  heading: {
    backgroundColor: "#f5f7f5",
    display: "none",
    borderBottom: "1px solid rgb(224, 224, 224)",
    [theme.breakpoints.up("768")]: {
      display: "flex",
    },
  },
  headingColumn: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  columnContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  smallCol: {
    boxSizing: "border-box",
    width: "100%",
    [theme.breakpoints.up("768")]: {
      padding: "1rem",
      width: "14%",
    },
  },
  largeCol: {
    boxSizing: "border-box",
    paddingBottom: "1.5rem",
    width: "100%",
    [theme.breakpoints.up("768")]: {
      padding: "1rem",
      width: "43%",
    },
  },
  month: {
    fontSize: "1.2em",
    fontWeight: 700,
    margin: "1.5rem 0 1.75rem 0",
    [theme.breakpoints.up("768")]: {
      fontSize: "1em",
      fontWeight: 400,
      margin: 0,
      padding: "0 0.5rem",
    },
    [theme.breakpoints.up("1024")]: {
      padding: "0 1rem",
    },
  },
}));

export default function SelectFlavour(props) {
  const classes = useStyles();
  const [openSelectFlavourDialog, setOpenSelectFlavourDialog] = useState(false);
  const [month, setMonth] = useState("");
  const [flavour, setFlavour] = useState("");
  const [isAllFlavoursFilled, setIsAllFlavoursFilled] = useState(false);
  const [currentDelivery, setCurrentDelivery] = useState({});
  const [flavourSku, setFlavourSku] = useState("");
  const [flavoursSent, setFlavoursSent] = useState(false);

  const flavours = useFlavourSelection();

  useEffect(() => {
    if (props && props.match.params.id) {
      flavours.fetchSubscription(props.match.params.id);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (flavours.status === "loaded") {
      setIsAllFlavoursFilled(flavours.isAllSelected());
    }
  }, [flavours]);

  const handleSelectFlavourClick = (event, delivery, flavour) => {
    setFlavourSku(
      isFlavour1(flavour) ? delivery.flavour1_sku : delivery.flavour2_sku
    );
    setFlavour(flavour);
    setMonth(delivery.complete_month);
    setCurrentDelivery(delivery);
    setOpenSelectFlavourDialog(true);
  };

  const flavourSelected = (selectedFlavour) => {
    flavours.selectFlavour(
      currentDelivery.delivery_id,
      isFlavour1(flavour),
      selectedFlavour
    );
    closeSelectFlavourDialog();
  };

  const closeSelectFlavourDialog = () => {
    setOpenSelectFlavourDialog(false);
  };

  const isFlavour1 = (flavour) => flavour.indexOf("1") > 0;

  const handleSendFlavours = async () => {
    const isSent = await flavours.submitSelections();
    setFlavoursSent(isSent);
  };

  const sortByDeliveryRank = (a, b) => {
    return b.delivery_rank - a.delivery_rank;
  };

  return (
    <Container maxWidth="lg">
      <SelectFlavourDialog
        isOpen={openSelectFlavourDialog}
        onClose={() => setOpenSelectFlavourDialog(false)}
        onFlavourSelect={flavourSelected}
        month={month}
        flavour={flavour}
        flavour_sku={flavourSku}
      />

      <Paper>
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 15,
            minHeight: "95vh",
          }}
        >
          <img className={classes.logo} src={logo} alt="Chaeban" />

          <div className={classes.root}>
            <hr />
            <h3>
              Select Initial Items for your subscription
              {flavours.status === "loaded" &&
                ` (${flavours.data[0].gift_reciever_email})`}
            </h3>
            {flavours.status === "loading" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgressBySize />
              </div>
            )}
            {flavours.status === "loaded" && (
              <>
                <div className={classes.heading}>
                  <div
                    className={`${classes.headingColumn} ${classes.smallCol}`}
                  >
                    Month
                  </div>
                  <div
                    className={`${classes.headingColumn} ${classes.largeCol}`}
                  >
                    Item 1
                  </div>
                  <div
                    className={`${classes.headingColumn} ${classes.largeCol}`}
                  >
                    Item 2
                  </div>
                </div>
                {flavours.data.sort(sortByDeliveryRank).map((delivery) => (
                  <div
                    className={classes.columnContainer}
                    key={delivery.delivery_id}
                  >
                    <div className={`${classes.smallCol} ${classes.month}`}>
                      {delivery.complete_month}
                    </div>
                    <div className={classes.largeCol}>
                      <FlavourSelectButton
                        delivery={delivery}
                        disabled={flavoursSent}
                        flavour={1}
                        onClick={(e) =>
                          handleSelectFlavourClick(e, delivery, "Item 1")
                        }
                      />
                    </div>
                    <div className={classes.largeCol}>
                      <FlavourSelectButton
                        delivery={delivery}
                        disabled={flavoursSent}
                        flavour={2}
                        onClick={(e) =>
                          handleSelectFlavourClick(e, delivery, "Item 2")
                        }
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>

          <span style={{ marginTop: 25 }}>
            <Button
              disabled={!isAllFlavoursFilled || flavoursSent}
              variant="contained"
              color="primary"
              onClick={handleSendFlavours}
            >
              Submit Selections
            </Button>
          </span>
        </span>
      </Paper>
    </Container>
  );
}
