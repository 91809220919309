import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import SendIcon from "@material-ui/icons/Send";
import OnFleetIcon from "@material-ui/icons/LocalShipping";
import { CircularProgressBySize } from "../Progress/CircularProgressBySize";
import SearchBar from "./SearchBar";
import { useSubscriptions } from "../../services/useSubscriptions";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    justifyContent: "space-between",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
    fontWeight: "bold",
  },
  searchBar: {
    flex: "1 1 100%",
  },
  buttons: {
    flex: "1 1 100%",
    textAlign: "right",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { selectedData, onSearch } = props;
  const subscriptions = useSubscriptions();

  const withSelectedRowForNotification = () => {
    const subscriptionsToNotify = selectedData.filter((s) => !s.email_sent);
    return subscriptionsToNotify && subscriptionsToNotify.length > 0;
  };

  const withSelectedRowForOnfleet = () => {
    const eligibleSubscriptions = selectedData.filter(
      (s) => s.email_sent && s.flavour_selected
    );
    return eligibleSubscriptions && eligibleSubscriptions.length > 0;
  };

  const [
    processingNotificationState,
    setProcessingNotificationState,
  ] = useState(false);

  const [processingOnFleetState, setProcessingOnFleetState] = useState(false);

  const handleSendNotificationClick = async (event) => {
    if (processingNotificationState) return;

    event.preventDefault();

    setProcessingNotificationState(true);
    const subscriptionsToNotify = selectedData.filter(
      (s) => !s.email_sent && s.validEmailFormat
    );

    await subscriptions.sendNotification(subscriptionsToNotify);
    setProcessingNotificationState(false);
  };

  const handleOnfleetCreateOrderClick = async (event) => {
    if (processingOnFleetState) return;

    event.preventDefault();

    setProcessingOnFleetState(true);
    const eligibleSubscriptions = selectedData.filter(
      (s) => s.email_sent && s.flavour_selected
    );

    await subscriptions.sendToOnFleet(eligibleSubscriptions);
    setProcessingOnFleetState(false);
  };

  return (
    <Toolbar className={clsx(classes.root)}>
      {
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Gift Subscription
          <span style={{ fontSize: 14, fontStyle: "italic" }}>
            {selectedData.length > 0
              ? `  (${selectedData.length}) Selected `
              : " "}{" "}
          </span>
        </Typography>
      }
      <SearchBar onSearch={onSearch} className={classes.searchBar} />
      {
        <span className={classes.buttons}>
          <Tooltip title="Send Notification" placement="top-start" arrow>
            <span>
              <IconButton
                disabled={!withSelectedRowForNotification()}
                aria-label="Send Notification"
                onClick={handleSendNotificationClick}
              >
                {processingNotificationState ? (
                  <CircularProgressBySize size={20} />
                ) : (
                  <SendIcon />
                )}
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Create OnFleet Order" placement="top-start" arrow>
            <span>
              <IconButton
                disabled={!withSelectedRowForOnfleet()}
                aria-label="Create OnFleet Order"
                onClick={handleOnfleetCreateOrderClick}
              >
                {processingOnFleetState ? (
                  <CircularProgressBySize size={20} />
                ) : (
                  <OnFleetIcon />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </span>
      }
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default EnhancedTableToolbar;
