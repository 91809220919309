import React from "react";
import { Switch, Route } from "react-router-dom";
import Products from "../Products/Products";
import Orders from "../Orders/Orders";
import Reports from "../Reports/Reports";
import GiftSubscriptions from "../GiftSubscription/GiftSubscription";
import Home from "../Home/Home";
import { ProductsProvider } from "../../services/useProducts";
import { SubscriptionsProvider } from "../../services/useSubscriptions";
import { ConmanProvider } from "../../services/useConman";
import DeliveryPage from '../Delivery/delivery-page.component'
import Unassigned from "../UnassignedOrders/unassigned.component";

function SideMenuRenderer() {
  return (
    <Switch>
      <Route path="/gift-subscriptions">
        <SubscriptionsProvider>
          <GiftSubscriptions />
        </SubscriptionsProvider>
      </Route>
      <Route path="/products">
        <ProductsProvider>
          <Products />
        </ProductsProvider>
      </Route>
      <Route path="/conman">
        <ConmanProvider>
          <Orders />
        </ConmanProvider>
      </Route>
      <Route path="/reports" component={Reports} />
      <Route path="/delivery" component={DeliveryPage} />
      <Route path="/unassigned" component={Unassigned} />
      <Route exact path="/" component={Home} /> 
    </Switch>
  );
}

export default SideMenuRenderer;
