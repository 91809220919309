

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import ChaebanLogo from './ChaebanLogo'
import './Summary.styles.css'
import { Link } from "react-router-dom";
import axios from 'axios'
import { useSnackbar } from 'notistack';
import { Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ConfirmationPopup from './OrderConfimationPopup/order-confirmation.component'
import { ReactComponent as Loader } from '../../assets/loader.svg'


let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
    link = `http://localhost:4000/`;
} else {
    env = url.split('.')[0];
    sec = env.split('//')[1];
    if (sec === 'secure2') {
        link = `https://${sec}.chaebanicecream.com/public_api/`;
    } else if (sec === 'secure3') {
        link = `https://${sec}.chaebanicecream.com/public_api/`;
    } else {
        link = `http://localhost:4000/`;
    }
}



const NonEditableSummaryPage = ({
    routesAvailable,
    group,
    customerId,
    zone,
    otherFlavorInfo, payload, subsription, deliveryRank, otp, oid, pintsQty
}) => {


    const history = useHistory()

    const [groupInfo, setGroupInfo] = useState([])



    useEffect(() => {
        findDeliveryTimeWindow(routesAvailable, group)
    }, [routesAvailable, group])

    const findDeliveryTimeWindow = (routesAvailable, selectedGroup) => {
        setGroupInfo(routesAvailable.find(group => group.group_id == selectedGroup))

    }

    if (subsription == 'GIFT') {
        var groupInfoString = `This gift subscription magic link is outdated, please contact club@chaebanicecream.com if you are missing your latest magic link.`
    } else {
        if (groupInfo) {
            if (groupInfo.length !== 0) {
                if ((group !== 0) && (group !== -1)) {
                    let groupStartTime = groupInfo.group_start_time
                    let groupEndTime = groupInfo.group_end_time
                    var groupInfoString = `Your order is being prepared and will be out for delivery soon.`
                }
            }
        } else {

            if (group === '0') {
                var groupInfoString = 'Please pickup the order from store between the store hours.'
            }

            if (group === '-1') {
                var groupInfoString = `Someone from the team will contact you ASAP.`

            }

        }
    }



    return (
        <div>
            <div className='order-summary layout--centered'>
                {
                    deliveryRank != null ?
                        null :
                        <Link
                            to={{
                                pathname: `/clubUpdateFlavours/${customerId}`,
                                state: {
                                    zone,
                                    group,
                                    payload,
                                    subsription,
                                    deliveryRank,
                                    customerId,
                                    otp,
                                    otherFlavorInfo,
                                    routesAvailable,
                                    oid, 
                                    pintsQty
                                }
                            }}
                            className="flavor-selection-list__back">
                            {<div><ArrowBackIcon style={{ fontSize: 30 }} />
                                <Typography variant="srOnly">Return to order list</Typography></div>}

                        </Link>
                }

                <ChaebanLogo />
                <h1 >Order Summary</h1>
                <div className='flav-summary-container'>
                    <div className='flav-items-wrapper'>
                        {
                            otherFlavorInfo.map((flavor) => {
                                return (
                                    <div className='flavor-item_summary'>
                                        <div className='flavor-item__img-wrapper_summary' style={{ backgroundImage: `url('${flavor.flavor.image.src}')` }}>
                                        </div>
                                        <div className='flavor-item__text_summary'>
                                            <div className="flavor-item__name_summary">{flavor.flavor.title}</div>
                                        </div>
                                        {flavor.quantity != null && <div className="flavor-item__quantity">×{flavor.quantity}</div>}

                                    </div>



                                )
                            })
                        }
                    </div>
                    <div className='del-pref'>
                        {/*  {
                            otp ==true ?
                                <div>
                                    <span>Your order has been dispatched</span>
                                    <br />
                                </div> : <span>{groupInfoString} </span>
                        }*/}
                        <span>{groupInfoString}</span>


                    </div>
                </div>

                <Link className='Next-Page-Button'
                    to={{
                        pathname: `/clubUpdateFlavours/flavorSelection/${customerId}/addon`,
                        state: {
                            subsription,
                            customerId
                        }
                    }}

                >
                    Add-Ons
                </Link>

            </div>
        </div>


    )
}

export default NonEditableSummaryPage;