import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { isValidEmail } from "../helpers/validator";
import {
  DASHBOARD_MESSAGES,
  SNACKBAR_VARIANT,
} from "../components/Core/Constants";

const SubscriptionsContext = createContext(undefined);

let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
  link = `http://localhost:3000/`;
} else {
  env = url.split('.')[0];
  sec = env.split('//')[1];
  if (sec === 'secure2') {
    link = `https://${sec}.chaebanicecream.com/api/`;
  } else if (sec === 'secure3') {
    link = `https://${sec}.chaebanicecream.com/api/`;
  } else {
    link = `http://localhost:3000/`;
  }
}


function SubscriptionsProvider({ children }) {
  const [subscriptions, setSubscriptions] = useState([]);
  const [store, setStore] = useState({ status: "loading" });
  const [searchFields, setSearchFields] = useState({
    term: "",
    field: "shopify_ordername",
  });
  const [filters, setFilters] = useState({
    email_sent: "both",
    onfleet_created: "both",
    flavour_selected: "both",
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const result = await axios.get(`${link}subscriptions`);
        return result.data.recordset;
      } catch (error) {
        setStore({ status: "error", error });
        enqueueSnackbar(DASHBOARD_MESSAGES.FetchData.Subscriptions.Error, {
          variant: SNACKBAR_VARIANT.Error,
        });
        return [];
      }
    };

    const fetchDeliveries = async () => {
      try {
        const result = await axios.get(`${link}subscriptions/deliveries`);
        return result.data.recordset;
      } catch (error) {
        setStore({ status: "error", error });
        enqueueSnackbar(DASHBOARD_MESSAGES.FetchData.Deliveries.Error, {
          variant: SNACKBAR_VARIANT.Error,
        });
        return [];
      }
    };

    const constructSubscriptions = async () => {
      const subs = await fetchSubscriptions();
      const deliveries = await fetchDeliveries();

      if (subs.length > 0 && deliveries.length > 0) {
        const mutatedSubs = subs.map((subscription) => {
          subscription.id = subscription.subscriber_id;
          subscription.validEmailFormat = isValidEmail(
            subscription.gift_reciever_email
          );
          subscription.deliveries = deliveries
            .sort((a, b) => b.delivery_rank - a.delivery_rank)
            .filter((d) => d.subscriber_id === subscription.subscriber_id);

          return subscription;
        });

        setSubscriptions(mutatedSubs);
        setStore({ status: "loaded", data: mutatedSubs });
      }
    };

    constructSubscriptions();
  }, [enqueueSnackbar]);

  useEffect(() => {
    const { term, field } = searchFields;
    let subs = subscriptions;

    if (term) {
      subs = subscriptions.filter((sub) =>
        sub[field].toLowerCase().includes(term.toLowerCase())
      );
    }

    Object.keys(filters).forEach((key) => {
      if (filters[key] !== "both") {
        const isTrue = filters[key] === "true";
        subs = subs.filter((sub) => sub[key] === isTrue);
      }
    });

    setStore((store) => {
      return { ...store, data: subs };
    });
  }, [subscriptions, searchFields, filters]);

  const searchSubs = (searchFields) => {
    setSearchFields(searchFields);
  };

  const filterSubs = (key, val) => {
    setFilters({ ...filters, [key]: val });
  };

  const update = useCallback(
    async (key, payload) => {
      let endpoint = "";
      if (key === "gift_reciever_email") endpoint = `${link}updatesubscription`;
      if (key === "notification_date") endpoint = `${link}reschedule`;
      if (!endpoint) return;

      try {
        await axios.put(endpoint, payload);
        let updatedSubs = subscriptions;
        const subIdx = updatedSubs.findIndex(
          (s) => s.subscriber_id === payload.subscriber_id
        );
        if (subIdx > -1) {
          if (key === "gift_reciever_email") {
            updatedSubs[subIdx].gift_reciever_email =
              payload.gift_reciever_email;
            updatedSubs[subIdx].email_sent = false;
          }
          if (key === "notification_date") {
            updatedSubs[subIdx].notification_date = payload.notification_date;
          }
        }
        enqueueSnackbar(DASHBOARD_MESSAGES.Update.Success, {
          variant: SNACKBAR_VARIANT.Success,
        });
        setSubscriptions([...updatedSubs]);
        setStore((store) => {
          return { ...store, data: updatedSubs };
        });
      } catch {
        enqueueSnackbar(DASHBOARD_MESSAGES.Update.Error, {
          variant: SNACKBAR_VARIANT.Error,
        });
      }
    },
    [enqueueSnackbar, subscriptions]
  );

  const updateDelivery = useCallback(
    async (subscriberId, payload) => {
      try {
        await axios.put(`${link}orders`, payload);
        let updatedSubs = subscriptions;
        const subIdx = updatedSubs.findIndex(
          (s) => s.subscriber_id === subscriberId
        );
        if (subIdx > -1) {
          const data = payload[0];
          updatedSubs[subIdx].deliveries.forEach((delivery) => {
            if (delivery.delivery_id === data.delivery_id) {
              delivery.gift_reciever_phone = data.gift_reciever_phone;
              delivery.gift_reciever_address = data.gift_reciever_address;
              delivery.personal_message = data.personal_message;
            }
          });
        }
        enqueueSnackbar(DASHBOARD_MESSAGES.Update.Success, {
          variant: SNACKBAR_VARIANT.Success,
        });
        setSubscriptions([...updatedSubs]);
        setStore((store) => {
          return { ...store, data: updatedSubs };
        });
      } catch {
        enqueueSnackbar(DASHBOARD_MESSAGES.Update.Error, {
          variant: SNACKBAR_VARIANT.Error,
        });
      }
    },
    [enqueueSnackbar, subscriptions]
  );

  const sendNotification = useCallback(
    async (toNotify) => {
      const payload = toNotify.map((s) => ({
        shopify_id: s.shopify_id,
        gifter_name: s.gifter_name,
        gifter_email: s.gifter_email,
        gift_reciever_name: s.gift_reciever_name,
        gift_reciever_email: s.gift_reciever_email,
        months: s.months,
        subscriber_id: s.subscriber_id,
      }));

      if (payload.length <= 0) {
        enqueueSnackbar(DASHBOARD_MESSAGES.Notification.NoSubscription, {
          variant: SNACKBAR_VARIANT.Info,
        });
        return;
      }

      try {
        await axios.post(`${link}sendnotification`, payload);
        let updatedSubs = subscriptions;
        toNotify.forEach((sub) => {
          const subIdx = updatedSubs.findIndex(
            (s) => s.subscriber_id === sub.subscriber_id
          );
          if (subIdx > -1) {
            updatedSubs[subIdx].email_sent = true;
          }
        });
        enqueueSnackbar(DASHBOARD_MESSAGES.Notification.Success, {
          variant: SNACKBAR_VARIANT.Success,
        });
        setSubscriptions([...updatedSubs]);
        setStore((store) => {
          return { ...store, data: updatedSubs };
        });
      } catch (error) {
        enqueueSnackbar(DASHBOARD_MESSAGES.Notification.Error, {
          variant: SNACKBAR_VARIANT.Error,
        });
      }
    },
    [enqueueSnackbar, subscriptions]
  );

  const sendToOnFleet = useCallback(
    async (toOnFleet) => {
      const payload = toOnFleet.map((s) => ({
        shopify_id: s.shopify_id,
        subscriber_id: s.subscriber_id,
        gift_reciever_email: s.gift_reciever_email,
      }));

      if (payload.length <= 0) {
        enqueueSnackbar(DASHBOARD_MESSAGES.OnFleet.NoSubscription, {
          variant: SNACKBAR_VARIANT.Info,
        });
        return;
      }

      try {
        await axios.post(`${link}createorders`, payload);
        let updatedSubs = subscriptions;
        toOnFleet.forEach((sub) => {
          const subIdx = updatedSubs.findIndex(
            (s) => s.subscriber_id === sub.subscriber_id
          );
          if (subIdx > -1) {
            updatedSubs[subIdx].onfleet_created = true;
          }
        });
        enqueueSnackbar(DASHBOARD_MESSAGES.OnFleet.Success, {
          variant: SNACKBAR_VARIANT.Success,
        });
        setSubscriptions([...updatedSubs]);
        setStore((store) => {
          return { ...store, data: updatedSubs };
        });
      } catch (error) {
        enqueueSnackbar(DASHBOARD_MESSAGES.Notification.Error, {
          variant: SNACKBAR_VARIANT.Error,
        });
      }
    },
    [enqueueSnackbar, subscriptions]
  );

  const mutators = {
    update,
    updateDelivery,
    sendNotification,
    sendToOnFleet,
    searchSubs,
    filterSubs,
  };

  return (
    <SubscriptionsContext.Provider value={{ ...store, ...mutators }}>
      {children}
    </SubscriptionsContext.Provider>
  );
}

function useSubscriptions() {
  const context = useContext(SubscriptionsContext);
  if (context === undefined) {
    throw new Error(
      "useSubscriptions must be used within SubscriptionsProvider"
    );
  }
  return context;
}

export { SubscriptionsProvider, useSubscriptions };
