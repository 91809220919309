import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import ChaebanLogo from './ChaebanLogo'
import './Summary.styles.css'
import { Link } from "react-router-dom";
import axios from 'axios'
import { useSnackbar } from 'notistack';
import { Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ConfirmationPopup from './OrderConfimationPopup/order-confirmation.component'
import { ReactComponent as Loader } from '../../assets/loader.svg'


let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
    link = `http://localhost:4000/`;
} else {
    env = url.split('.')[0];
    sec = env.split('//')[1];
    if (sec === 'secure2') {
        link = `https://${sec}.chaebanicecream.com/public_api/`;
    } else if (sec === 'secure3') {
        link = `https://${sec}.chaebanicecream.com/public_api/`;
    } else {
        link = `http://localhost:4000/`;
    }
}



const SummaryPage = () => {

    // const location = useLocation()
    const history = useHistory()
    // const { enqueueSnackbar } = useSnackbar();

    const [groupInfo, setGroupInfo] = useState([])
    const [orderConfirmation, setOrderConfirmation] = useState(0)
    const [isOrderSuccess, setIsOrderSuccess] = useState(true)
    const [isFlavSubmited, setIsFlavSubmited] = useState(true)


    let zone = (window.sessionStorage.getItem('zone'))
    let group = (window.sessionStorage.getItem('group'))
    let payload = JSON.parse(window.sessionStorage.getItem('payload'))
    let subsription = (window.sessionStorage.getItem('subsription'))
    let deliveryRank = (window.sessionStorage.getItem('deliveryRank'))
    let customerId = (window.sessionStorage.getItem('customerId'))
    let otp = (window.sessionStorage.getItem('otp'))
    let otherFlavorInfo = JSON.parse(window.sessionStorage.getItem('otherFlavorInfo'))
    let routesAvailable = JSON.parse(window.sessionStorage.getItem('routesAvailable'))
    let oid = (window.sessionStorage.getItem('oid'))
    let pintsQty = (window.sessionStorage.getItem('pintsQty'))


    useEffect(() => {
        findDeliveryTimeWindow(routesAvailable, group)
    }, [])

    const findDeliveryTimeWindow = (routesAvailable, selectedGroup) => {
        setGroupInfo(routesAvailable.find(group => group.group_id == selectedGroup))

    }

    if (groupInfo) {
        if (groupInfo.length !== 0) {
            if ((group !== 0) && (group !== -1)) {
                let groupStartTime = groupInfo.group_start_time
                let groupEndTime = groupInfo.group_end_time
                // var groupInfoString = `Your delivery zone is ${zone}, and your order will be delivered on ${groupStartTime.split("T")[0]} in-between ${groupStartTime.split("T")[1].toString().slice(0, 5)} to ${groupEndTime.split("T")[1].toString().slice(0, 5)}`
                var groupInfoString = `Your order will be delivered on ${groupStartTime.split("T")[0]} in-between ${groupStartTime.split("T")[1].toString().slice(0, 5)} to ${groupEndTime.split("T")[1].toString().slice(0, 5)}`
            }
        }
    } else {
        if (group === '0') {
            var groupInfoString = 'You have selected store pick up. Please pick it up at the store during store hours.'
        }

        if (group === '-1') {
            var groupInfoString = `As you are requesting, we will contact you to arrange a custom delivery time this month.`

        }

    }

    const handlePopupClose = () => {
        history.push(`/clubUpdateFlavours/${customerId}`)
        setOrderConfirmation(0)
    }

    const handleRouteToAddon = () => {
        history.push({
            pathname: `/clubUpdateFlavours/flavorSelection/${customerId}/addon`,
            state: {
                subsription,
                customerId
            }
        })
        setOrderConfirmation(0)

    }


    const handleSummarySubmit = async () => {


        setIsFlavSubmited(false)
        try {
            const res = await axios.put(`${link}updatedClubFlavors/${oid}`, payload, {
                params: {
                    subsription,
                    deliveryRank,
                    customerId,
                    zone,
                    group,
                    otp,
                    pintsQty

                }
            });
            setIsFlavSubmited(true)
            setOrderConfirmation(1)
            setIsOrderSuccess(true)


        }
        catch {
            setIsFlavSubmited(true)
            setOrderConfirmation(1)
            setIsOrderSuccess(false)
        }




        // enqueueSnackbar('Your order has been updated!', { variant: 'success' });
    }

    return (
        <div>
            {orderConfirmation == 1 ?
                <ConfirmationPopup className='confirmation-popup' handlePopupClose={handlePopupClose} handleRouteToAddon={handleRouteToAddon} isOrderSuccess={isOrderSuccess} subsription={subsription}/> : null
            }
            {!isFlavSubmited ?
                <div className='loader-container'>
                    <Loader className="loader" />
                </div> :
                <div className='order-summary layout--centered'>
                    <Link
                        to={{
                            pathname: `/clubUpdateFlavours/flavorSelection/${customerId}/deliveryselection`,
                            state: {
                                zone,
                                group,
                                payload,
                                subsription,
                                deliveryRank: JSON.parse(deliveryRank),
                                customerId,
                                otp,
                                otherFlavorInfo,
                                routesAvailable,
                                oid,
                                pintsQty
                            }
                        }}
                        className="flavor-selection-list__back">
                        {<div><ArrowBackIcon style={{ fontSize: 30 }} />
                            <Typography variant="srOnly">Return to order list</Typography></div>}

                    </Link>
                    <ChaebanLogo />
                    <h1 >Order Summary</h1>
                    <div className='flav-summary-container'>
                        <div className='flav-items-wrapper'>
                            {
                                otherFlavorInfo.map((flavor) => {
                                    return (
                                        <div className='flavor-item_summary'>
                                            <div className='flavor-item__img-wrapper_summary' style={{ backgroundImage: `url('${flavor.flavor.image.src}')` }}>
                                            </div>
                                            <div className='flavor-item__text_summary'>
                                                <div className="flavor-item__name_summary">{flavor.flavor.title}</div>
                                            </div>
                                            {flavor.quantity != null && <div className="flavor-item__quantity">×{flavor.quantity}</div>}

                                        </div>



                                    )
                                })
                            }
                        </div>
                        <div className='del-pref'>
                            <span>{groupInfoString} </span>

                        </div>
                    </div>

                    <Link className='Next-Page-Button'
                        // to={{
                        //     pathname: `/clubUpdateFlavours/flavorSelection/${customerId}/addon`,
                        // }} 
                        onClick={handleSummarySubmit}
                    >
                        Submit Order!
                    </Link>

                </div>

            }
        </div>


    )
}

export default SummaryPage;