import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import './route-lock.styles.css'
import logo from '../../assets/images/chaebanlogo.png'

let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
    link = `http://localhost:4000/`;
} else {
    env = url.split('.')[0];
    sec = env.split('//')[1];
    if (sec === 'secure2') {
        link = `https://${sec}.chaebanicecream.com/public_api/`;
    } else if (sec === 'secure3') {
        link = `https://${sec}.chaebanicecream.com/public_api/`;
    } else {
        link = `http://localhost:4000/`;
    }
}

const RouteLockPage = () => {

    const location = useLocation()
    const history = useHistory()
    const { routeName, routeID, picklistID, driverName } = location.state



    const handleLockOrder = async () => {

   

        history.push({
            pathname: `${location.pathname}/picklist`,
            state: {
                routeID,
                picklistID,
                driverName
            }
        })


    }

    return (
        <div className='lockorder-homepage'>
            <div className='logo-picklist'>
                <img src={logo} alt='chaeban-logo' />
            </div>
            <div className='lockorder-text'>
                Do you want to freeze the below route?
            </div>
            <button className='lockorder-button' onClick={handleLockOrder}>
                Freeze Route {routeName}
            </button>

        </div>
    )
}

export default RouteLockPage;