import React, { useEffect } from "react";
import parse from "html-react-parser";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import defaultProductImage from "./../../assets/images/defaultProductImage.png";

const useStyles = makeStyles((theme) => ({
  conatiner: {
    display: "flex",
    flexDirection: "column",
  },
  image: {
    width: 200,
    height: 200,
  },
  description: {
    padding: 10,
    textAlign: "justify",
  },
}));

export default function FlavourInfoDialog(props) {
  const isOpen = props.isOpen;
  const handleCloseDialog = props.handleOnCloseProductInfoDialog;
  const product = props.product;
  const classes = useStyles();

  const [open, setOpen] = React.useState(isOpen);

  const handleClose = () => {
    handleCloseDialog();
  };

  useEffect(() => {
    setOpen(isOpen);
    return () => {
      setOpen(false);
    };
    // eslint-disable-next-line
  }, [props]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        onClick={handleClose}
      >
        <DialogTitle id="select-flavour-dialog-title">
          {product.title}
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <img
              className={classes.image}
              src={product.image ? product.image.src : defaultProductImage}
              alt={product.title}
            />
            <div className={classes.description}>
              {parse(product.body_html)}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
