import React, { useState, useRef } from "react";
import moment from "moment";
import {
  Input,
  IconButton,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import BackspaceIcon from "@material-ui/icons/Backspace";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  editIcon: {
    padding: 0,
    fontSize: 16,
  },
}));

export default function NotificationDateField({ disabled, date, onSave }) {
  const [editMode, setEditMode] = useState(false);
  const [localDate, setLocalDate] = useState(null);
  const flatpickrRef = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    setLocalDate(moment(date).toISOString());
  }, [date]);

  const handleChange = (d) => {
    setLocalDate(moment(d[0]).toISOString());
  };

  const clearLocalDate = () => {
    setLocalDate(null);
  };

  const handleSave = () => {
    setEditMode(false);
    onSave(localDate);
  };

  const handleClose = () => {
    setLocalDate(date);
    setEditMode(false);
  };

  const now = new Date();

  return editMode ? (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <Flatpickr
          defaultValue={localDate ? localDate : ""}
          value={localDate ? localDate : ""}
          options={{
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "Z",
            minDate: now.setHours(24, 0, 0, 0), // Midnight tonight
          }}
          ref={flatpickrRef}
          onChange={handleChange}
          render={({ defaultValue, value, ...props }, ref) => {
            return (
              <Input
                defaultValue={defaultValue}
                inputRef={ref}
                {...props}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Clear date field"
                      size="small"
                      onClick={clearLocalDate}
                    >
                      <BackspaceIcon className={classes.editIcon} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            );
          }}
        />
      </div>
      <div>
        <IconButton onClick={handleSave}>
          <CheckIcon className={classes.editIcon} />
        </IconButton>
        <IconButton onClick={handleClose}>
          <CloseIcon className={classes.editIcon} />
        </IconButton>
      </div>
    </div>
  ) : (
    <div style={{ display: "flex" }}>
      <IconButton
        style={{ padding: 5 }}
        disabled={disabled}
        onClick={() => setEditMode(true)}
      >
        <EditIcon className={classes.editIcon} />
      </IconButton>
      <div>{date ? moment(date).format("DD-MM-yyyy") : "Not Scheduled"}</div>
    </div>
  );
}
