import React from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ColumnFilter from "./ColumnFilter";

export function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    numSelected,
    rowCount,
    order,
    orderBy,
    handleOrderDateSorting,
    onFilter,
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "Select All subscriptions" }}
          />
        </TableCell>
        <TableCell id="expander" width="10"></TableCell>
        <TableCell width="150" className={classes.tableHeader}>
          Subscription Id
        </TableCell>
        <TableCell width="150" className={classes.tableHeader}>
          <TableSortLabel
            active={orderBy === "created_date"}
            direction={orderBy === "created_date" ? order : "asc"}
            onClick={handleOrderDateSorting}
          >
            Order Date
          </TableSortLabel>
        </TableCell>
        <TableCell width="100" className={classes.tableHeader}>
          Order Name
        </TableCell>
        <TableCell width="150" className={classes.tableHeader}>
          Gifter Name
        </TableCell>
        <TableCell width="250" className={classes.tableHeader}>
          Gifter Email
        </TableCell>
        <TableCell width="250" className={classes.tableHeader}>
          Gift Receiver Email
        </TableCell>
        <TableCell width="75" className={classes.tableHeader}>
          Month(s)
        </TableCell>
        <TableCell width="150" className={classes.tableHeader}>
          Total Amount
        </TableCell>
        <TableCell width="170" className={classes.tableHeader}>
          Notification Date
        </TableCell>
        <TableCell width="80" className={classes.tableHeader}>
          Email Sent
          <ColumnFilter
            label="Sent"
            onChange={(val) => onFilter("email_sent", val)}
          />
        </TableCell>
        <TableCell width="80" className={classes.tableHeader}>
          OnFleet
          <ColumnFilter
            label="Created"
            onChange={(val) => onFilter("onfleet_created", val)}
          />
        </TableCell>
        <TableCell width="80" className={classes.tableHeader}>
          Flavour Selected
          <ColumnFilter
            label="Selected"
            onChange={(val) => onFilter("flavour_selected", val)}
          />
        </TableCell>
        <TableCell width="100" className={classes.tableHeader}>
          Fulfillment Status
        </TableCell>
        <TableCell width="100" className={classes.tableHeader}>
          Instruction
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  handleOrderDateSorting: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
};
