import React, { useState, useEffect } from "react";
import Dashboard from "./components/dashboard/Dashboard";
import SignIn from "./components/SignIn/SignInControl";
import moment from "moment";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ProductsProvider } from "./services/useProducts";
import { FlavourSelectionProvider } from "./services/useFlavourSelection";
import SelectFlavour from "./components/SelectFlavour/SelectFlavour";
import FlavorSelectionList from "./components/ClubFlavorSelection/FlavorSelectionList";
import OrderNumberSelect from "./components/ClubFlavorSelection/OrderNumberSelect";
import ZoneMapImage from "./components/DeliveryZones/ZoneMapImage/zone-map-image.component";
import DeliveryZonesSelection from './components/DeliveryZones/delivery-zones-selection.component'
import SummaryPage from "./components/ClubFlavorSelection/Summary.component";
import AddOn from "./components/ShopifyBuy/AddOn.component";
import PickListHome from "./components/PickList/picklist-home.component.jsx"
import RouteLockPage from "./components/PickList/route-lock.component.jsx"
import PicklistTable from './components/PickList/picklist-table.component'



const userContextKey = "userContext";

const userState = {
  username: "",
  isLoggedIn: false,
  expiration: new Date(),
  subscriptions: null,
};

function retrieveCachedUser() {
  const cachedUserContext = sessionStorage.getItem(userContextKey);
  if (cachedUserContext) {
    //TODO: RMS - Re-authenticate after 12hrs
    const userContext = JSON.parse(cachedUserContext);
    if (moment(userContext.expiration) <= moment()) {
      sessionStorage.removeItem(userContextKey);
    } else {
      userState.isLoggedIn = userContext.isLoggedIn;
      userState.username = userContext.username;
    }
  }
}


retrieveCachedUser();

const App = () => {
  // eslint-disable-next-line no-unused-vars
  const [isLoggedIn, setIsLoggedIn] = useState(userState.isLoggedIn);




  const onLogIn = (e) => {
    retrieveCachedUser();
    setIsLoggedIn(true);
  };

  const onLogout = (e) => {
    sessionStorage.removeItem(userContextKey);
    setIsLoggedIn(false);
  };



  return (
    <Switch>
      <Route
        path="/select_flavour/:id"
        render={(props) => (
          <ProductsProvider>
            <FlavourSelectionProvider>
              <SelectFlavour {...props} />
            </FlavourSelectionProvider>
          </ProductsProvider>
        )}
      />

      <Route path="/login">
        <SignIn onLogIn={onLogIn} />
      </Route>
      <Route
        exact
        path='/clubUpdateFlavours/:id'
        component={OrderNumberSelect}
      />
      <Route
        exact
        path='/clubUpdateFlavours/flavorSelection/:orderNumber'
        component={FlavorSelectionList}
      />
      <Route
        exact
        path='/clubUpdateFlavours/flavorSelection/:orderNumber/deliveryselection'
        component={DeliveryZonesSelection}
      />
      <Route
        exact
        path='/clubUpdateFlavours/flavorSelection/:orderNumber/summary'
        component={SummaryPage}
      />
      <Route
        exact
        path='/clubUpdateFlavours/flavorSelection/:orderNumber/addon'
        component={AddOn}
      />
      <Route
        exact
        path='/driver/:driverID'
        component={PickListHome}
      />
      <Route
        exact
        path='/driver/:driverID/lockroute'
        component={RouteLockPage}
      />
      <Route
        exact
        path='/driver/:driverID/lockroute/picklist'
        component={PicklistTable}
      />
      <Route
        exact
        path='/zonemap'
        component={ZoneMapImage}
      />

      <Route path="/">
        <Dashboard userContext={userState} onLogout={onLogout} />
      </Route>
    </Switch>
  );
};

export default App;
