import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: "1.5rem",
  },
  reason: {
    marginBottom: "1.5rem",
  },
}));

function CustomerUpdateDialog({ show, order, onClose, onSubmit }) {
  const [fields, setFields] = useState({
    email: "",
    phone: "",
    address: "",
    zip: "",
  });
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (order) {
      setFields({
        email: order.email || "",
        phone: order.phone || "",
        address: order.address || "",
        zip: order.zip || "",
      });
    }
    if (!show) {
      setFields({ email: "", phone: "", address: "", zip: "" });
      setLoading(false);
    }
  }, [order, show]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((fields) => ({ ...fields, [name]: value }));
  };

  const handleSubmit = () => {
    setLoading(true);
    const payload = {
      ...fields,
      name: order.name,
      order_id: order.order_id,
      customer_id: order.customer_id,
      order_number: order.order_number,
      line_items: order.line_items,
    };
    onSubmit(payload);
  };

  return (
    <Dialog open={show} onClose={onClose}>
      <DialogTitle>Update Customer Details</DialogTitle>
      <DialogContent>
        {order && (
          <DialogContentText className={classes.reason}>
            <b>Onfleet reason for failure:</b> {order.onfleet_message}
          </DialogContentText>
        )}
        <TextField
          fullWidth
          id="email"
          name="email"
          className={classes.input}
          label="Email"
          variant="outlined"
          onChange={handleChange}
          value={fields.email}
        />
        <TextField
          fullWidth
          id="phone"
          name="phone"
          className={classes.input}
          label="Phone"
          variant="outlined"
          onChange={handleChange}
          value={fields.phone}
        />
        <TextField
          fullWidth
          id="address"
          name="address"
          className={classes.input}
          label="Address"
          variant="outlined"
          onChange={handleChange}
          value={fields.address}
        />
        <TextField
          fullWidth
          id="zip"
          name="zip"
          className={classes.input}
          label="Zip"
          variant="outlined"
          onChange={handleChange}
          value={fields.zip}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {!loading ? "Update" : <CircularProgress size={20} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomerUpdateDialog;
