import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import './edit-zone-submit.styles.css'
import { withRouter } from 'react-router';
import axios from 'axios';

let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
    link = `http://localhost:3000/`;
} else {
    env = url.split('.')[0];
    sec = env.split('//')[1];
    if (sec === 'secure2') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else if (sec === 'secure3') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else {
        link = `http://localhost:3000/`;
    }
}


const EditZoneSubmit = ({ history, zoneNameToEdit }) => {
    const [editedZoneName, setEditedZoneName] = useState(zoneNameToEdit)
    const [editedZoneDescription, setEditedZoneDescription] = useState('')

    const handleEditZoneSubmit = async () => {

        await axios.put(`${link}editZone`,
            {
                zoneName: editedZoneName,
                zoneDescription: editedZoneDescription
            }
        )
        history.push('/delivery')


    }

    const changeEditZoneName = (event) => {
        setEditedZoneName(event.target.value)
    }

    const changeEditZoneDescription = (event) => {
        setEditedZoneDescription(event.target.value)
    }


    return (
        <div className='edit-zone-form'>
            <Box
                component="form"
                sx={{
                    mx: 'auto',
                    textAlign: 'center',
                    '& .MuiTextField-root': { m: 1, width: 3/4 },
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <TextField
                        required
                        type="text"
                        id="username"
                        name="username"
                        label="Zone Name"
                        defaultValue={zoneNameToEdit}
                        onChange={changeEditZoneName}
                        disabled
                    />
                    <TextField
                        required
                        type="text"
                        id="username"
                        name="username"
                        label="Zone Description"
                        defaultValue=""
                        onChange={changeEditZoneDescription}
                    />
                </div>
            </Box>
            <div className='submit-edit-zone'>
                <Button type='submit' value="Submit" variant="contained" onClick={handleEditZoneSubmit}>Update Zone!!</Button>
            </div>
        </div>

    );
}

export default withRouter(EditZoneSubmit);
