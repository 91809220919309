import { Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import defaultProductImage from '../../assets/images/defaultProductImage.png';
import { resizeImg } from '../../helpers/resize-img';
import ChaebanLogo from './ChaebanLogo';
import FlavorPicker from './FlavorPicker';
import './FlavorSelectionList.css';
import FlavorItem from './FlavorItem';
import UserForm from './UserForm';
import DeliveryZonesSelection from '../DeliveryZones/delivery-zones-selection.component'
import NonEditableSummaryPage from './Summary-non-editable.component';
// import ZoneImageData from '../../ZoneImageData.js'
import Card from './Card'


let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
  link = `http://localhost:4000/`;
} else {
  env = url.split('.')[0];
  sec = env.split('//')[1];
  if (sec === 'secure2') {
    link = `https://${sec}.chaebanicecream.com/public_api/`;
  } else if (sec === 'secure3') {
    link = `https://${sec}.chaebanicecream.com/public_api/`;
  } else {
    link = `http://localhost:4000/`;
  }
}

export default function FlavorSelectionList() {


  const history = useHistory();

  const location = useLocation();
  //const { customerId, currentMonth, isEditable } = location.state;
  const { enqueueSnackbar } = useSnackbar();

  const [availableFlavors, setAvailableFlavors] = useState([]); //required to send the available products to display
  const [pintsQty, setPintsQty] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [customerAddress, setCustomerAddress] = useState("")

  // NEW DATA STRUCTURES
  const [selectedPints, setSelectedPints] = useState([]); // Master array of all selected pints
  const [selectedFlavorsWithQuantity, setSelectedFlavorsWithQuantity] = useState([]); // Array containing selected flavors and quantities
  const [changingFlavor, setChangingFlavor] = useState(''); // ID of the flavor currently being changed
  const [changedPints] = useState(new Map());
  const [isRouted, setIsRouted] = useState(true)
  const query = new URLSearchParams(location.search);
  const [subscriptionType, setSubscriptionType] = useState('')
  const [isEditable, setIsEditable] = useState('1')
  const [deliveryStatus, setDeliveryStatus] = useState()
  const [currentMonth, setCurrentMonth] = useState()
  const [initialFlavorId, setInitialFlavorId] = useState([])

  // let isEditable=1;

  let { orderNumber } = useParams()
  let oid = orderNumber.split('?')[0]


  // let oid = orderNumber
  let customerId = query.get("customerId") || (orderNumber.split('&')[0]).split('customerId=')[1]
  // let currentMonth = query.get("currentMonth") || (orderNumber.split('=')[3]).split('&')[0]
  // let isEditable = query.get("isEditable") || (orderNumber.split('=')[4])
  let deliveryRank = query.get("deliveryRank")
  var otp

  if (location.state == undefined)  // BACK BUTTON INSTANCE
  {
    // console.log("Back button intnacccc")
    // console.log(location)
    if (location.search != "") {
      otp = location.search.split('&')[1].split('otp=')[1] || false
      // console.log(otp, "OOOTTTTPPPP")
    } else {
      // console.log("OTPPPP INSIDEE ELSEEEE")
      otp = false
      deliveryRank = (window.sessionStorage.getItem('deliveryRank'))
      // console.log("OTPPPP INSIDEE ELSEEEE", otp)
    }
  }
  // else if (location.state){
  //   console.log("Inside elseifffff")
  //   otp = location.state.otp
  // }
  else {
    if (location.search == '') // ROuting from orders page
    {
      // console.log("Routing from orders page")
      otp = (location.pathname.split('&')[1]).split('otp=')[1]

    } else // refreshed page
    {
      // console.log("refreshed pageeee")
      otp = location.search.split('&')[1].split('otp=')[1]

    }
  }


  

  const [selectedZone, setSelectedZone] = useState("")
  const [selectedZoneId, setSelectedZoneId] = useState("")
  const [selectedRoute, setSelectedRoute] = useState("")
  const [routesAvailable, setRoutesAvailable] = useState([]) // should be filled with axio post below
  const [ZoneImages, setZoneImages] = useState([])
  const [isFlavPickerOpen, setIsFlavPickerOpen] = useState(false)
  const [cardClose, setCardStatus] = useState('1')
  // const [summaryPageFlavorInfo, setSummaryPageFlavorInfo] = useState([])
  const [otherFlavorInfo, setOtherFlavorInfo] = useState([])




  // const handleCardClose = () => {
  //   setCardStatus('1')
  // }


  // const setUserSelectedZone = async (name, id) => {

  //   if (selectedZoneId !== id) {
  //     setSelectedZone(name)
  //     setSelectedZoneId(id)
  //     setSelectedRoute("")
  //     const routes = await axios.get(`${link}getGroup/${id}`)
  //     setRoutesAvailable(routes.data)
  //   }

  // }

  // const handleSelectRoute = ({ target: { value } }) => {
  //   setSelectedRoute(value)
  // }

  /**
   * ABove is for deliveyr zone selector
   */



  useEffect(() => {
    // sessionStorage.clear();
    window.sessionStorage.setItem('customerId', customerId)
    
    getFlavors();
    getZones();

    if (!location.state) {
      setIsRouted(false)
    }

    if (deliveryRank === null) {
      setSubscriptionType('REGULAR')
      window.sessionStorage.setItem('subsription', 'REGULAR')
    }
    else if (deliveryRank !== null) {
      setSubscriptionType('GIFT')
      window.sessionStorage.setItem('subsription', 'GIFT')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);



  useEffect(() => {
    setSelectedFlavorsWithQuantity(() => {
      const quantityTable = {};

      selectedPints.forEach((pint) => {
        quantityTable[pint] = (quantityTable[pint] || 0) + 1;
      });

      const flavorsWithQuantity = Object.entries(quantityTable)
        .map(([flavorId, quantity]) => ({
          flavor: availableFlavors.find((flavor) => `${flavor.id}` === flavorId),
          quantity,
        }))
        .sort((a, b) => a.flavor.title.localeCompare(b.flavor.title));
      setSelectedFlavorsWithQuantity(flavorsWithQuantity);
      setOtherFlavorInfo(flavorsWithQuantity)
      flavorsWithQuantity.forEach(({ flavor, quantity }) => {
        payload[`${flavor.id}`] = quantity;
      });

    });
  }, [availableFlavors, selectedPints]);

  // useEffect(() => {
  //   unhighlightDeliverySelection()
  // }, [selectedRoute])

  // const unhighlightDeliverySelection = () => {
  //   if ((selectedRoute || selectedRoute === 0)) {
  //     setDeliveryHighlight(false)
  //   }
  // }

  const getZones = async () => {
    const zonesData = await axios.get(`${link}getActiveZones`)
    setZoneImages(zonesData.data.recordset)
  }


  const getFlavors = async () => {

    setIsLoading(true);

    const response = await axios.get(`${link}clubUpdateFlavours/${oid}`, {
      params: {
        customerId: customerId,
        // currentMonth: currentMonth,
        // isEditable: isEditable,
        deliveryRank: deliveryRank,
        otp: otp

      }

    });


    if (!response.data) {
      setIsLoaded(false);
    } else {
      if (!response.data.productDataAvailable) {
        setIsEditable(response.data.PintsInfo[0].isEditableStatus);
        setDeliveryStatus(response.data.PintsInfo[0].deliveryStatus)
        setCardStatus(response.data.PintsInfo[0].isEditableStatus)
        setCurrentMonth(response.data.PintsInfo[0].currentMonth)
        setCustomerAddress(response.data.PintsInfo[0].customerAddress)
        setSelectedZoneId(response.data.PintsInfo[0].zone)
        setSelectedRoute(response.data.PintsInfo[0].group)
        if (response.data.PintsInfo[0].zone != null) {
          const routes = await axios.get(`${link}getGroup/${response.data.PintsInfo[0].zone}`, {
            params: {
              selectedGroupId: response.data.PintsInfo[0].group
            }
          })
          setRoutesAvailable(routes.data)
        }
        setIsLoaded(true);

      } else {
        setIsEditable(response.data.PintsInfo[0].isEditableStatus);
        setDeliveryStatus(response.data.PintsInfo[0].deliveryStatus)
        setCardStatus(response.data.PintsInfo[0].isEditableStatus)
        setCurrentMonth(response.data.PintsInfo[0].currentMonth)
        setCustomerAddress(response.data.PintsInfo[0].customerAddress)
        setSelectedZoneId(response.data.PintsInfo[0].zone)
        setSelectedRoute(response.data.PintsInfo[0].group)
        if (response.data.PintsInfo[0].zone != null) {
          const routes = await axios.get(`${link}getGroup/${response.data.PintsInfo[0].zone}`, {
            params: {
              selectedGroupId: response.data.PintsInfo[0].group
            }
          })
          setRoutesAvailable(routes.data)
        }
        setAvailableFlavors(resizeImages(response.data.productDataAvailable));
        setPintsQty(
          response.data.PintsInfo.reduce((total, pintsInfo) => total + pintsInfo.pintsQty, 0)
        );
        setSelectedPints(buildInitialPintsArray(response.data.InitialFlavorInfo));
        setInitialFlavorId(buildInitialPintsArray(response.data.InitialFlavorInfo))
        setIsLoaded(true);

      }


    }
    setIsLoading(false);
  };

  // const handleIncompletedSubmit = async () => {
  //   setDeliveryHighlight(true)
  //   alert("Please select the delivery preference")
  //   setDeliveryHighlight(false)
  // }

  const resizeImages = (flavors) => {
    flavors.forEach((flavor) => {
      if (flavor.image && flavor.image.src) {
        flavor.image.src = resizeImg(flavor.image.src);
      } else {
        flavor.image = { src: defaultProductImage };
      }
    });

    return flavors;
  };

  const payload = {};
  // const otherFlavorInfo = []


  const buildInitialPintsArray = (initialFlavorInfo) => {
    const pints = [];

    initialFlavorInfo.forEach((flavor) => {
      for (let i = 0; i < flavor.initialQty; i++) {
        pints.push(flavor.initialProductID);
      }
    });
    return pints;
  };

  const submit = async (event) => {

    window.sessionStorage.setItem('deliveryRank', deliveryRank)
    window.sessionStorage.setItem('oid', oid)

    if (isEditable === "0") {
      return;
    }

    const inventoryError = checkInventoryLevel(initialFlavorId);

    if (inventoryError) {
      enqueueSnackbar(
        `Only ${inventoryError.inventoryQty} remaining from ${inventoryError.flavorName}.`
      );
    } else {
      setOtherFlavorInfo(selectedFlavorsWithQuantity)
      selectedFlavorsWithQuantity.forEach(({ flavor, quantity }) => {
        payload[`${flavor.id}`] = quantity;
      });


      // await axios.put(`${link}updatedClubFlavors/${oid}`, payload, {
      //   params: {
      //     subsription: subscriptionType,
      //     deliveryRank: deliveryRank,
      //     customerId: customerId,
      //     zone: selectedZoneId,
      //     group: selectedRoute,
      //     otp: otp

      //   }
      // });

      // enqueueSnackbar('Your order has been updated!', { variant: 'success' });

      // if ((customerId) && subscriptionType === 'REGULAR') {

      //   history.push(`/clubUpdateFlavours/${customerId}`);
      // }
      // else {
      //   window.location.reload();

      // }
    }
  };

  const checkInventoryLevel = (initialFlavorId) => {

    for (let item of selectedFlavorsWithQuantity) {

      if (!initialFlavorId.includes(item.flavor.id)) {
        const inventoryQty = item.flavor.variants[0].inventory_quantity;
        const flavorName = item.flavor.title;
        let remainingInventory = inventoryQty - item.quantity;

        if (remainingInventory < 0) {
          return { flavorName, inventoryQty };
        }
      }
    }

    return null;
  };

  const openFlavorPicker = (flavor) => {
    setIsFlavPickerOpen(true)

    if ((isEditable === "1") && !changingFlavor) {
      setChangingFlavor(flavor.id);
    }
  };

  const closeFlavorPicker = () => {
    setChangingFlavor(null);
    setIsFlavPickerOpen(false)
  };

  const getUnsavedFlavorsMessage = () =>
    `You have ${changedPints.size} unsaved ${changedPints.size === 1 ? 'flavor' : 'flavors'}!`;

  function handleSubmit(event) {
    event.preventDefault();
    submit(event);
  }

  const updateFlavor = (previousFlavorId, newFlavor) => {
    if (previousFlavorId === newFlavor.id) {
      closeFlavorPicker();
      return;
    }
    const currentPints = [...selectedPints];
    const previousFlavorIndex = currentPints.findIndex((pint) => pint === previousFlavorId);
    currentPints[previousFlavorIndex] = newFlavor.id;
    setSelectedPints(currentPints);

    changedPints.set(previousFlavorIndex, newFlavor.id);

    closeFlavorPicker();
  };

  return (
    ((isEditable === '1') && ((deliveryStatus == null)||(deliveryStatus == 'Not Completed'))) ?
      <div className="flavor-selection-list layout--centered">
        {
          otp == 'true' ? <DeliveryZonesSelection customerId={customerId} /> :
            <div className="flavor-selection-list layout--centered">
              {deliveryRank == null ?
                <Link to={`/clubUpdateFlavours/${customerId}`} className="flavor-selection-list__back">
                  {<div><ArrowBackIcon style={{ fontSize: 30 }} />
                    <Typography variant="srOnly">Return to order list</Typography></div>}

                </Link> : null}
              <ChaebanLogo />
              <h1>Change Your Selection</h1>

              {!isLoading && isLoaded ? (
                <>
                  <p className="flavor-selection-list__order-info">
                    <span>Order {oid}</span>
                    {currentMonth && <span>{currentMonth}</span>}
                    <span>{pintsQty} Pints</span>
                  </p>
                  <p className="flavor-selection-list__order-info"> Click on an item to update the product selection</p>
                  <div className='flav-list-container'>
                    <UserForm submit={submit} changedPints={changedPints.size} isEditable={isEditable}>
                      {selectedFlavorsWithQuantity &&
                        selectedFlavorsWithQuantity.map(({ flavor, quantity }) => (
                          <FlavorItem
                            key={flavor.id}
                            flavor={flavor}
                            quantity={quantity}
                            onSelectFlavor={openFlavorPicker}
                          />
                        ))}
                    </UserForm>
                  </div>
                  <Link className='Next-Page-Button' to={{
                    pathname: `/clubUpdateFlavours/flavorSelection/${customerId}/deliveryselection`,
                    state: {
                      payload,
                      subsription: subscriptionType,
                      deliveryRank,
                      customerId,
                      otp,
                      oid,
                      otherFlavorInfo,
                      pintsQty
                    }
                  }}
                    onClick={submit}
                  >
                  Delivery Preference ->
                  </Link>
                </>
              ) : (
                <p>Loading your order...</p>
              )}

              {changingFlavor && (
                <FlavorPicker
                  availableFlavors={availableFlavors}
                  currentFlavorId={changingFlavor}
                  onUpdateFlavor={updateFlavor}
                  onClose={closeFlavorPicker}
                />
              )}


            </div>

        }

      </div> : <NonEditableSummaryPage
        routesAvailable={routesAvailable}
        group={selectedRoute}
        customerId={customerId}
        zone={selectedZoneId}
        otherFlavorInfo={otherFlavorInfo}
        payload={payload}
        subsription={subscriptionType}
        deliveryRank={deliveryRank}
        otp={otp}
        oid={oid} 
        pintsQty={pintsQty}/>
  );
}
