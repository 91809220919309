import React, { useState, useEffect } from 'react'
import './delivery-page.styles.css'
import SelectZone from './ZoneSelect/zone-select.component';
import AddEditZoneSelector from './AddEditZone/add-edit-zone.component';
import AddZone from './AddEditZone/AddZone/add-zone.component';
import EditZone from './AddEditZone/EditZoneSelector/edit-zone.component';
import EditZoneSubmit from './AddEditZone/EditZoneSubmit/edit-zone-submit.component';
import RoutesInfo from './RoutesInfo/routes-info.component'
import AddRoute from './RoutesInfo/AddRoute/add-route.component';
import EditRouteSelection from './RoutesInfo/EditRouteSelection/edit-route-selection.component';
import OrdersTable from './RoutesInfo/OrdersTable/orders-table.component';
import DeliveryZonesSelection from '../DeliveryZones/delivery-zones-selection.component'
import { Route, Switch, withRouter } from 'react-router';
import axios from 'axios'
import AllZonesData from './RoutesInfo/AllZoneData/all-zone-data.component';


let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
    link = `http://localhost:3000/`;
} else {
    env = url.split('.')[0];
    sec = env.split('//')[1];
    if (sec === 'secure2') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else if (sec === 'secure3') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else {
        link = `http://localhost:3000/`;
    }
}



const DeliveryPage = ({ history }) => {

    const [zoneName, setZoneName] = useState('');
    const [zoneNameToEdit, setZoneNameToEdit] = useState('')
    const [zoneInfo, setZoneInfo] = useState([])
    const [zoneId, setZoneId] = useState("")
    const [selectedRoutes, setSelectedRoutes] = useState([]);


    useEffect(() => {
        getAllZoneData();
    }, []);


    const getAllZoneData = async () => {
        const zoneData = await axios.get(`${link}getZones`)
        setZoneInfo(zoneData.data.recordset)
    }



    const handleSelectZone = ({ target: { value } }) => {

        if (value === 'Add/EditZone') {
            history.push('/delivery/addEditZone')

        }
        else if (value === 'AllZones') {
            history.push('/delivery/allZones')


        }
        else {
            setZoneName(value['zone_name'])
            setZoneId(value['zone_id'])
            history.push('/delivery/routes')

        }
    };

    const handleZoneNameToEdit = ({ target: { value } }) => {
        setZoneNameToEdit(value)
        history.push('/delivery/editZone/Submit')
    }





    return (
        <div>
            <Switch>
                <Route exact path='/delivery' render={() => (<SelectZone handleSelectZone={handleSelectZone} zoneName={zoneName} ZoneData={zoneInfo} setZoneInfo={setZoneInfo} />)} />
                <Route exact path='/delivery/addEditZone' component={AddEditZoneSelector} />
                <Route exact path='/delivery/allZones' render={() => (<AllZonesData zoneName={zoneName} zoneId={zoneId} setZoneId={setZoneId} selectedRoutes={selectedRoutes} setSelectedRoutes={setSelectedRoutes} />)} />
                <Route exact path='/delivery/addZone' render={() => (<AddZone />)} />
                <Route exact path='/delivery/editZone' render={() => (<EditZone handleZoneNameToEdit={handleZoneNameToEdit} zoneNameToEdit={zoneNameToEdit} />)} />
                <Route exact path='/delivery/editZone/Submit' render={() => (<EditZoneSubmit zoneNameToEdit={zoneNameToEdit} />)} />
                <Route exact path='/delivery/routes' render={() => (<RoutesInfo zoneName={zoneName} zoneId={zoneId} selectedRoutes={selectedRoutes} setSelectedRoutes={setSelectedRoutes} />)} />
                <Route exact path='/delivery/routes/editRoute' render={() => (<EditRouteSelection zoneName={zoneName} zoneId={zoneId} />)} />
                <Route exact path='/delivery/routes/addRoute' render={() => (<AddRoute zoneName={zoneName} zoneId={zoneId} />)} />
                <Route exact path='/delivery/routes/orders' render={() => (<OrdersTable zoneName={zoneName} zoneId={zoneId} ZoneData={zoneInfo} selectedRoutes={selectedRoutes} />)} />
                <Route exact path='/delivery/aa' component={DeliveryZonesSelection} />

            </Switch>
        </div>)
}

export default withRouter(DeliveryPage);


