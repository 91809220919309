import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export const CircularProgressBySize = (props) => {
  const defaultSize = props ? props.size : 40;
  return (
    <div>
      <CircularProgress size={defaultSize} />
    </div>
  );
};
