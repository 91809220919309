import React, { useState } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { CircularProgressBySize } from "../Progress/CircularProgressBySize";
import { UpdateDeliveryDialog } from "./UpdateDeliveryDialog";
import { useSubscriptions } from "../../services/useSubscriptions";

export function ChildRow(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [savingState, setSavingState] = useState(false);

  const { delivery, subscriberId } = props;
  const subscriptions = useSubscriptions();

  const childIcon = {
    padding: 0,
    fontSize: 18,
  };

  const handleEditRowClick = () => {
    setIsOpen(true);

    setEditMode(!editMode);
  };

  const handleOnCloseDeliveryDialog = () => {
    setIsOpen(false);
  };

  const handleSave = async (delivery) => {
    setIsOpen(false);
    setSavingState(true);
    const payload = [
      {
        shopify_id: delivery.shopify_id,
        delivery_id: delivery.delivery_id,
        fromEmail: false,
        gift_reciever_phone: delivery.gift_reciever_phone,
        gift_reciever_address: delivery.gift_reciever_address,
        personal_message: delivery.personal_message,
      },
    ];

    await subscriptions.updateDelivery(subscriberId, payload);
    setSavingState(false);
  };

  return (
    <>
      <UpdateDeliveryDialog
        delivery={delivery}
        isOpen={isOpen}
        handleSave={handleSave}
        handleOnCloseDeliveryDialog={handleOnCloseDeliveryDialog}
      />
      <TableRow>
        <TableCell>
          {savingState ? (
            <CircularProgressBySize size={20} />
          ) : (
            <IconButton onClick={handleEditRowClick}>
              <EditIcon style={childIcon} />
            </IconButton>
          )}
        </TableCell>
        <TableCell>{delivery.delivery_id}</TableCell>
        <TableCell>{delivery.complete_month}</TableCell>
        <TableCell component="th" scope="row">
          {props.delivery.gift_reciever_name}
        </TableCell>

        <TableCell align="left" style={{ wordBreak: "break-all" }}>
          {delivery.gift_reciever_phone}
        </TableCell>
        <TableCell align="left">{delivery.gift_reciever_address}</TableCell>
        <TableCell align="left">{delivery.flavour1_name}</TableCell>
        <TableCell align="left">{delivery.flavour2_name}</TableCell>
        <TableCell colSpan={3}>{delivery.personal_message}</TableCell>
      </TableRow>
    </>
  );
}
