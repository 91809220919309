import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import axios from "axios";
import moment from "moment";
import Link from "@material-ui/core/Link";
import * as Constant from "../Core/Constants";
import Typography from "@material-ui/core/Typography";

const messages = Constant.DASHBOARD_MESSAGES;
const snackBar = Constant.SNACKBAR_VARIANT;

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function RecentSubscriptions() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    async function subscriptions() {
      try {
        await axios.get("/api/subscriptions").then((response) => {
          if (response.status === 200) {
            let firstTenSubscriptions = [];
            if (response.data.recordset.length > 0) {
              firstTenSubscriptions = response.data.recordset
                .sort(
                  (a, b) => new Date(b.created_date) - new Date(a.created_date)
                )
                .slice(0, 10);
            }
            setSubscriptions(firstTenSubscriptions);
          } else {
            enqueueSnackbar(messages.FetchData.Subscriptions.Error, {
              variant: snackBar.Error,
            });
          }
        });
      } catch (e) {
        enqueueSnackbar(messages.FetchData.Subscriptions.Error, {
          variant: snackBar.Error,
        });
      }
    }

    subscriptions();

    // eslint-disable-next-line
  }, []);

  const handleSeeDetails = () => {
    document.getElementById("gift_subscriptions").click();
  };

  return (
    <React.Fragment>
      <Typography component="h6" variant="h6" color="inherit" noWrap>
        Recent Subscriptions
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Gifter Name</TableCell>
            <TableCell>Gift Reciever Email</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptions.map((row) => (
            <TableRow key={row.subscriber_id}>
              <TableCell>
                {moment(row.created_date).format("MMM DD YYYY, hh:mm a")}
              </TableCell>
              <TableCell>{row.gifter_name}</TableCell>
              <TableCell>{row.gift_reciever_email}</TableCell>
              <TableCell>{row.total_amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        <Link
          color="primary"
          style={{ cursor: "pointer" }}
          onClick={handleSeeDetails}
        >
          See more details on Gift Subscriptions page...
        </Link>
      </div>
    </React.Fragment>
  );
}
