import React from 'react';
import './order-confirmation.styles.css'
import { Button } from "@material-ui/core";


const ConfirmationPopup = ({ handlePopupClose, handleRouteToAddon, isOrderSuccess, subsription }) => {
    let message = ''
    if (subsription == undefined) {
        message = 'WE HAVE RECEIVED YOUR DELIVERY PREFERENCE'
    } else {
        message = 'WE HAVE RECEIVED YOUR FLAVOR SELECTIONS & DELIVERY PREFERENCE'
    }
    return (
        isOrderSuccess ?
            <div className='pop-up-card-confirmation success'>
                <h1>{message}</h1>
                {/*<span>If you would like to buy more please press AddOn button below</span>*/}
                <div className='close-button'>
                    { /* <Button variant="outlined" onClick={handlePopupClose}>Go To Home Page</Button> */}
                    <Button variant="outlined" onClick={handleRouteToAddon}>Okay!</Button>
                </div>
            </div>
            :
            <div className='pop-up-card-confirmation fail'>
                <h1>SOMETHING WENT WRONG!</h1>
                <span>Please email club@chaebanicecream.com, and our support team will be happy to help.</span>
                <div className='close-button'>
                    <Button variant="outlined" onClick={handlePopupClose}>Try Again!</Button>
                </div>
            </div>

    )
}

export default ConfirmationPopup;
