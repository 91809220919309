import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import './unassigned-orders.styles.css'
// import CustomizedSnackbarsSuccess from '../../Snackbar/snackbar.component'
// import CustomizedSnackbarsFail from '../../Snackbar/snackbar-fail.component'


let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
    link = `http://localhost:3000/`;
} else {
    env = url.split('.')[0];
    sec = env.split('//')[1];
    if (sec === 'secure2') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else if (sec === 'secure3') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else {
        link = `http://localhost:3000/`;
    }
}





const UnassignedOrders = ({ history, zoneName, zoneId, ZoneData }) => {


    const columns = [
        { field: 'col1', headerName: ' GroupID', width: 130 },
        { field: 'col2', headerName: ' ZoneID', width: 130 },
        { field: 'col3', headerName: ' OrderNumber', width: 150 },
        { field: 'col4', headerName: 'CustomerName', width: 500 },
        { field: 'col5', headerName: 'LineItems', width: 500 },
        { field: 'col6', headerName: 'InOnfleet?', width: 150 },
        { field: 'col7', headerName: 'WorkerID', width: 150 },
        { field: 'col8', headerName: 'UpdateTime', width: 150 },
        { field: 'col9', headerName: 'IsEditable', width: 150 },
        { field: 'col10', headerName: 'DeliveryStatus', width: 150 },
        { field: 'col11', headerName: 'OnfleetID', width: 150 },
        { field: 'col12', headerName: 'DeliveryID', width: 150 }




    ];

    useEffect(() => {
        getOrderData()
        getRoutesData(zoneId)


    }, [])



    const getRoutesData = async (zoneId) => {
        const RoutesData = await axios.get(`${link}getGroup/${zoneId}`)
        setRoutesData(RoutesData.data.recordsets[0])
    }

    const getOrderData = async () => {
        try {
            const orderData = await axios.get(`${link}getUnassignedOrders/${zoneId}`)

            let clubOrdersData = []
            let giftOrdersData = []


            for (let i = 0; i < orderData.data[0].length; i++) {
                for (let j = 0; j < orderData.data[0][i].recordset.length; j++) {
                    clubOrdersData.push(orderData.data[0][i].recordset[j])
                }
            }

            for (let i = 0; i < orderData.data[1].length; i++) {
                for (let j = 0; j < orderData.data[1][i].recordset.length; j++) {
                    giftOrdersData.push(orderData.data[1][i].recordset[j])
                }
            }


            setOrderInfo(clubOrdersData)
            setGiftInfo(giftOrdersData)

        }
        catch (err) {
            console.log(err)
        }

    }

    const assignOrdersToRoute = async () => {
        try {
            await axios.post(`${link}assignOrdersToRoute`, {
                selectedRoute: selectedRoute,
                selectedOrders: selectedOrders

            })
            // // ordersArray = []
            // setSuccessAsignTask(true)
        }
        catch {
            // ordersArray = []
            // setFailAssignTask(true)
        }


    }



    const selectRoute = ({ target: { value } }) => {
        setSelectedRoute(value)


    }

    const [selectedOrders, setSelectedOrders] = useState([]);
    const [orderInfo, setOrderInfo] = useState([])
    const [giftInfo, setGiftInfo] = useState([])
    const [routesData, setRoutesData] = useState([])
    const [selectedRoute, setSelectedRoute] = useState('')

    // const [successAssignTask, setSuccessAsignTask] = useState(false)
    // const [failAssignTask, setFailAssignTask] = useState(false)


    let rows_orders = [];
    let k = 100000;
    for (let i = 0; i < orderInfo.length; i++) {
        k++;
        let lineItemArray = []
        let onfleetPresent = Boolean(orderInfo[i].onfleet_id)
        try {
            for (let j = 0; j < JSON.parse(orderInfo[i].line_items).length; j++) {
                lineItemArray.push(JSON.parse(orderInfo[i].line_items)[j].title)

            }
        }
        catch (err) {
            console.log(err)
        }
        rows_orders.push(
            {
                id: k, col1: orderInfo[i]["group_id"], col2: orderInfo[i]["zone_id"], col3: orderInfo[i]["order_number"], col4: orderInfo[i]["name"], col5: lineItemArray, col6: onfleetPresent,
                col7: orderInfo[i]['Worker_Id'], col8: orderInfo[i]["updated_at"], col9: orderInfo[i]["is_editable"], col10: orderInfo[i]["Delivery_Status"], col11: orderInfo[i]["onfleet_id"]
            })
    }

    const rows_gifts = giftInfo.map((Data, i) => {
        let onfleetPresent = Boolean(Data["onfleet_id"])

        return (
            {
                id: i, col1: Data["group_id"], col2: Data["zone_id"], col3: Data["shopify_ordername"], col4: Data["gift_reciever_name"][0], col5: `${Data["flavour1_name"]} & ${Data["flavour2_name"]}`, col6: onfleetPresent,
                col7: Data['worker_id'], col8: Data["order_updated_time"], col9: Data["is_editable"], col10: Data["delivery_status"], col11: Data["onfleet_id"], col12: Data["delivery_id"]
            }
        )
    })

    const rows = [...rows_gifts, ...rows_orders]




    return (
        <div className='unassign-orders-page'>
            <div className='unassign-orders-title'>
                Zone : {zoneName}
            </div>
            <div style={{ height: 500, width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            checkboxSelection
                            rows={rows}
                            columns={columns}
                            onSelectionModelChange={(ids) => {
                                const selectedIDs = new Set(ids);
                                const selectedRowData = rows.filter((row) =>
                                    selectedIDs.has(row.id)

                                );
                                setSelectedOrders(selectedRowData)
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='zone-dropdown'>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Zone</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={zoneName}
                        label="Select Zone"
                        onChange={""}
                        sx={{
                            borderRadius: '20px',

                        }}
                    >
                        {
                            ZoneData.map((zone, i) => {
                                return (
                                    <MenuItem key={i} value={zone}>{zone['zone_name']}</MenuItem>
                                )

                            })
                        }
                    </Select>
                </FormControl>

            </div>
            <div className='routes-dropdown'>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Route*</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedRoute}
                        label="Select route"
                        onChange={selectRoute}
                        sx={{
                            borderRadius: '20px',

                        }}
                    >
                        {
                            routesData.map(dropdownNumber => {
                                return <MenuItem value={dropdownNumber['group_id']}>{`${dropdownNumber['group_name']} ${dropdownNumber['group_start_time']} ${dropdownNumber['group_end_time']}`}</MenuItem>
                            })
                        }

                        {<MenuItem value={0}>Store_Pick_Up</MenuItem>}

                    </Select>
                </FormControl>
            </div>
            <div className='assign-route-submit'>
                <Stack direction="row" spacing={2}>
                    {selectedOrders.length !== 0 ?
                        <Button variant="contained" onClick={assignOrdersToRoute}>Assign to route</Button> :
                        <Button variant="contained" disabled onClick={assignOrdersToRoute}>Assign to route</Button>
                    }
                </Stack>

            </div>

        </div>
    )
}

export default withRouter(UnassignedOrders);

/**
 *

 */


