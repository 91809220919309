
import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { withRouter } from 'react-router';
import './add-edit-zone.styles.css'


const AddEditZoneSelector = ({ history }) => {

    const handleEditZone = async () => {
        history.push('/delivery/editZone')

    }

    const handleAddZone = () => {
        history.push('/delivery/addZone')
    }


    return (
        <div className='add-edit-zone-page'>
            <div className='add-edit-zone-page-text'>
                What you need to do?
            </div>
            <div className='add-edit-zone-page-selections'>
                <Stack direction="row" spacing={2}>
                    <Button onClick={handleEditZone}>EDIT ZONE</Button>
                    <Button onClick={handleAddZone}>Add ZONE</Button>

                </Stack>
            </div>
        </div>

    );
}

export default withRouter(AddEditZoneSelector)