import React from 'react';

import './FlavorItem.css';

const flavorTitleRegex = /[()]/;

const FlavorItem = ({ flavor, quantity, onSelectFlavor }) => {
  const [flavorName, flavorInfo] = flavor.title.split(flavorTitleRegex);

  return (
    <button type="button" className="flavor-item" onClick={() => onSelectFlavor(flavor)}>
      <div
        className="flavor-item__img-wrapper"
        style={{ backgroundImage: `url('${flavor.image.src}')` }}
      ></div>
      <div className="flavor-item__text">
        <div className="flavor-item__name">{flavorName}</div>
        {!!flavorInfo && <div className="flavor-item__info">{flavorInfo}</div>}
      </div>
      {quantity != null && <div className="flavor-item__quantity">×{quantity}</div>}
    </button>
  );
};

export default FlavorItem;
