import './picklist-table.styles.css'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import logo from '../../assets/images/chaebanlogo.png'
import { ReactComponent as Loader } from '../../assets/loader.svg'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    // root: {
    //     backgroundColor: "yellow"
    // },
    row: {
        backgroundColor: "lightgreen",
        whiteSpace: 'normal',
        wordBreak: 'break-word',
    }
}));

let link;
let link1;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
    link = `http://localhost:4000/`;
    link1 = `http://localhost:3000/`;
} else {
    env = url.split('.')[0];
    sec = env.split('//')[1];
    if (sec === 'secure2') {
        link = `https://${sec}.chaebanicecream.com/public_api/`;
        link1 = `https://${sec}.chaebanicecream.com/api/`;
    } else if (sec === 'secure3') {
        link = `https://${sec}.chaebanicecream.com/public_api/`;
        link1 = `https://${sec}.chaebanicecream.com/api/`;
    } else {
        link = `http://localhost:4000/`;
        link1 = `http://localhost:3000/`;
    }
}

const picklist_columns = [
    { field: 'col1', headerName: 'PickList Id', width: 150, hide: true },
    { field: 'col2', headerName: 'Product' },
    { field: 'col3', headerName: 'Qty', width: 100 },

];

const instructions_columns = [
    { field: 'col1', headerName: 'PickList Id', width: 300, hide: true },
    { field: 'col2', headerName: 'Order Id', width: 200 },
    { field: 'col3', headerName: 'Delivery Id', width: 150 },
    { field: 'col4', headerName: 'Instruction', width: 800 },
];

const PicklistTable = () => {
    const classes = useStyles();
    const location = useLocation()
    const { routeID, picklistID, driverName } = location.state

    useEffect(() => {
        getPicklistData()
    }, [])

    const [picklistProducts, setPicklistProducts] = useState([])
    const [picklistInstructions, setPicklistInstructions] = useState([])
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    /**
     * Below was used to MUI table
     */

    // const picklist_rows = picklistProducts.map((Data, i) => {
    //     return (
    //         { id: i, col1: Data["PickList_Id"], col2: Data["Item_Name"], col3: Data["Qty"] }
    //     )
    // })

    // const instruction_rows = picklistInstructions.map((Data, i) => {
    //     return (
    //         { id: i, col1: Data["PickList_Id"], col2: Data["Order_Id"], col3: Data["Delivery_Id"], col4: Data["Instructions"].replace("[", "").replace("]", "") }
    //     )
    // })

    const picklist_rows_updated = picklistProducts.map((Data, i) => {
        return (
            <tr key={i}>
                <td >{Data["Item_Name"]}</td>
                <td className='qty-col'>{Data["Qty"]}</td>
            </tr>
            
        )
    })



    const instruction_rows_updated = picklistInstructions.map((Data, i) => {
        return (
            <tr key={i}>
                <td>{Data["Delivery_Id"]}</td>
                <td>{Data["Instructions"].replace("[", "").replace("]", "")}</td>
            </tr>
        )
    })

    const getPicklistData = async () => {
        let picklistData = await axios.put(`${link}runpicklist`, {
            routeID,
            picklistID,
            driverName
        })
        let data = await axios.get(`${link}getPicklistData/${picklistID}`)
        setPicklistInstructions(data.data.instructionsData.recordset)
        setPicklistProducts(data.data.pintsData.recordset)
    }




    return (
        <div> {
            !picklistProducts.length ?
                <div className='loader-container'>
                    <Loader className="loader" />
                </div> :
                <div className='picklist-table-page'>
                    <div className='logo-picklist' >
                        <img src={logo} alt='chaeban-logo' />
                    </div>
                    <Box className={classes.root}
                        sx={{ width: '90vw', typography: 'body1' }}>
                        <TabContext value={value} >
                            <Box sx={{ borderBottom: 0, borderColor: 'var(--chaeban-secondary--border)', width: 'fit-content', margin: 'auto' }}>
                                <TabList centered
                                    sx={{}}
                                    onChange={handleChange}
                                    aria-label="lab API tabs example"
                                >
                                    <Tab label="Pick List" value="1" />
                                    <Tab label="Instructions" value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="1" >
                                {picklistProducts ?
                                    <table className="picklist-table" >
                                        <thead className=''>
                                            <tr>
                                                <th className='product-col' scope="col">Product</th>
                                                <th scope="col">Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody className=''>
                                            {picklist_rows_updated}
                                        </tbody>
                                    </table>
                                    : null}
                            </TabPanel>
                            <TabPanel value="2">
                                {picklistInstructions ?
                                    <table className="instructions-table" >
                                        <thead className=''>
                                            <tr>
                                                <th scope="col">Order Number</th>
                                                <th scope="col">Instructions</th>
                                            </tr>
                                        </thead>
                                        <tbody className=''>
                                            {instruction_rows_updated}
                                        </tbody>
                                    </table> : null}

                            </TabPanel>
                        </TabContext>
                    </Box>
                </div>

        }
        </div>
    )
}


export default PicklistTable;