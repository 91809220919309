import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core";

const useSearchBarStyles = makeStyles((theme) => ({
  grid: {
    width: "100%",
  },
  largeGridItem: {
    flexGrow: 1,
  },
  smallGridItem: {
    flexGrow: 0,
  },
  searchBar: {
    width: "100%",
  },
  select: {
    minWidth: "140px",
  },
}));

export default function SearchBar({ onSearch, className }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchByValue, setSearchByValue] = useState("shopify_ordername");
  const classes = useSearchBarStyles();

  const handleTextChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTextReset = (e) => {
    setSearchTerm("");
    onSearch("", searchByValue);
  };

  const handleSelectChange = (e) => {
    setSearchByValue(e.target.value);
  };

  const handleSearch = () => {
    onSearch(searchTerm, searchByValue);
  };

  return (
    <Paper elevation={0} className={className}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item className={classes.largeGridItem}>
          <TextField
            id="search-field"
            label="Search"
            value={searchTerm}
            onChange={handleTextChange}
            className={classes.searchBar}
            InputProps={{
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    aria-label="Reset Search Field"
                    onClick={handleTextReset}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item className={classes.smallGridItem}>
          <FormControl className={classes.select}>
            <Select
              id="search-by"
              value={searchByValue}
              onChange={handleSelectChange}
              disableUnderline
            >
              <MenuItem value="shopify_ordername">Order Number</MenuItem>
              <MenuItem value="gifter_name">Gifter Name</MenuItem>
              <MenuItem value="gifter_email">Gifter Email</MenuItem>
              <MenuItem value="gift_reciever_name">Recipient Name</MenuItem>
              <MenuItem value="gift_reciever_email">Recipient Email</MenuItem>
              <MenuItem value="shopify_id">Subscription Id</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item className={classes.smallGridItem}>
          <IconButton
            aria-label="Search for Gift Subscription"
            onClick={handleSearch}
          >
            <SearchIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
}
