import React, { useState } from "react";
import './add-route.styles.css'
import StartEndTimePage from "../StartEndTime/start-end-time.component";
import { withRouter } from "react-router";
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import moment from "moment";

let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
    link = `http://localhost:3000/`;
} else {
    env = url.split('.')[0];
    sec = env.split('//')[1];
    if (sec === 'secure2') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else if (sec === 'secure3') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else {
        link = `http://localhost:3000/`;
    }
}



const AddRoute = ({ history, zoneName, zoneId }) => {

    const [startdate, setStartDate] = useState(new Date())
    const [enddate, setEndDate] = useState(new Date())
    const [routeCapacity, setRouteCapacity] = useState('0')
    const [routeName, setRouteName] = useState("")
    const [isRouteActive, setIsRouteActive] = useState(false)



    const label = "Add Route"

    const handleROuteActiveSelection = (event) => {
        setIsRouteActive(event.target.checked)

    }

    const selectStartDate = (event) => {

        setStartDate(event.toString())
    }

    const selectEndDate = (event) => {
        setEndDate(event.toString())
    }

    const handleCapacityEnter = (event) => {
        setRouteCapacity(event.target.value)
    }

    const handleGroupNameEnter = (event) => {
        setRouteName(event.target.value)
    }

    const handleRouteAddSubmit = async () => {
        try {
            const resp = await axios.post(`${link}addGroup`, {
                groupName: routeName,
                zoneId: zoneId,
                zoneName: zoneName,
                startdate: startdate,
                enddate: enddate,
                groupCapacity: routeCapacity,
                isRouteActive: isRouteActive
            })
            await history.push('/delivery/routes')

        } catch (err) {
            console.log(err)
            history.push('/delivery/')

        }

    }

    return (
        <div className='add-route-page'>
            <div className='add-route-text'>Add a route</div>
            <div className='add-route-inputfield'>
                Route Name
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            required
                            type="text"
                            id="RouteName"
                            name="RouteName"
                            label="RouteName"
                            defaultValue=""
                            onChange={handleGroupNameEnter}
                        />
                    </div>
                </Box>
            </div>
            {
                routeName.length !== 0 ?
                    <StartEndTimePage selectStartDate={selectStartDate} startdate={startdate} selectEndDate={selectEndDate} enddate={enddate} handleRouteSubmit={handleRouteAddSubmit} label={label} handleCapacityEnter={handleCapacityEnter} handleROuteActiveSelection={handleROuteActiveSelection} />
                    : null
            }
        </div>
    )
}

export default withRouter(AddRoute);