import React from 'react';

import './UserForm.css';

const UserForm = ({ children }) => {




  return (
    <>
      <form className="flavor-change-form">
        {children}
      </form>
    </>
  );
};

export default UserForm;
