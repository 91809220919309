import React from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import FlavourGrid from "./FlavourGrid";
import { useProducts } from "../../services/useProducts";
import { CircularProgressLoading } from "../Progress/CircularProgressLoading";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  subtitle: {
    fontSize: 14,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, subtitle, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography variant="subtitle1" className={classes.subtitle}>
        {subtitle}
      </Typography>
    </MuiDialogTitle>
  );
});

export default function SelectFlavourDialog({
  isOpen,
  onClose,
  onFlavourSelect,
  month,
  flavour,
  flavour_sku,
}) {
  const flavours = useProducts();

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={isOpen}
      >
        <DialogTitle
          id="select-flavour-dialog-title"
          subtitle="Click on pictures of ice cream for full descriptions of each"
          onClose={onClose}
        >
          Select flavour for {month} ({flavour})
        </DialogTitle>
        <DialogContent>
          {flavours.status === "loading" && <CircularProgressLoading />}
          {flavours.status === "error" && <div>Error loading flavours</div>}
          {flavours.status === "loaded" && (
            <FlavourGrid
              flavours={flavours.data}
              flavourSelected={onFlavourSelect}
              flavour_sku={flavour_sku}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
