import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DoneIcon from "@material-ui/icons/Done";
import LoopIcon from "@material-ui/icons/Loop";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import { TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import moment from "moment";
import InstructionModal from "./InstructionModal";
import { isValidEmail } from "../../helpers/validator";
import { useStyles } from "./GiftSubscription";
import { ChildRow } from "./ChildRow";
import NotificationDateField from "./NotificationDateField";
import { useSubscriptions } from "../../services/useSubscriptions";

export function ParentRow(props) {
  const { row, handleMainRowClick, selectedMainRow } = props;
  const [open, setOpen] = React.useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const [updatedEmail, setUpdatedEmail] = useState("");
  const initialEmailState = { valid: true, message: "" };
  const [emailState, setEmailState] = useState(initialEmailState);
  const subscriptions = useSubscriptions();

  const labelId = `enhanced-table-checkbox `;
  const classes = useStyles();

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const isItemSelected = isSelected(row.id);

  const isSelectedMainRow = (id) => selectedMainRow.indexOf(id) !== -1;
  const isMainRowSelected = isSelectedMainRow(row.id);

  const handleClick = (event, row) => {
    const id = row.shopify_id;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    handleMainRowClick(row);
  };

  const handleEditGiftRecieverEmail = () => {
    setEditMode(true);
  };

  const handleEmailChange = (event) => {
    if (isValidEmail(event.target.value)) {
      setUpdatedEmail(event.target.value);
      setEmailState(initialEmailState);
    } else {
      setEmailState({ valid: false, message: "Invalid email format" });
    }
  };

  const handleSaveGiftRecieverEmail = () => {
    setEditMode(false);
    const payload = {
      subscriber_id: row.subscriber_id,
      gift_reciever_email: updatedEmail,
    };
    subscriptions.update("gift_reciever_email", payload);
  };

  const handleCancelGiftRecieverEmail = () => {
    setEditMode(false);
  };

  const handleSaveNotificationDate = (date) => {
    const payload = {
      subscriber_id: row.subscriber_id,
      notification_date: date,
    };
    subscriptions.update("notification_date", payload);
  };

  const styles = {
    childRowHeader: {
      fontWeight: "bold",
    },
    mainRowIcon: {
      fontSize: 18,
    },
    email: { wordBreak: "break-all" },
    editIcon: {
      padding: 0,
      fontSize: 16,
    },
  };

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            onClick={(event) => handleClick(event, row)}
            checked={isMainRowSelected}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            style={{ marginRight: 10 }}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell scope="row">{row.shopify_id}</TableCell>
        <TableCell scope="row">
          {moment(row.created_date).format("DD-MM-yyyy")}
        </TableCell>
        <TableCell scope="row">{row.shopify_ordername}</TableCell>
        <TableCell>{row.gifter_name}</TableCell>
        <TableCell style={styles.email}>{row.gifter_email}</TableCell>
        <TableCell
          style={styles.email}
          className={row.validEmailFormat ? "" : classes.invalidEmailFormat}
        >
          {editMode ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <TextField
                  helperText={emailState.message}
                  autoFocus
                  fullWidth
                  defaultValue={row.gift_reciever_email}
                  onChange={handleEmailChange}
                />
              </div>
              <div>
                <IconButton
                  onClick={handleSaveGiftRecieverEmail}
                  disabled={!emailState.valid}
                >
                  <CheckIcon style={styles.editIcon} />
                </IconButton>
                <IconButton onClick={handleCancelGiftRecieverEmail}>
                  <CloseIcon style={styles.editIcon} />
                </IconButton>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex" }}>
              <IconButton
                style={{ padding: 5 }}
                onClick={handleEditGiftRecieverEmail}
              >
                <EditIcon style={styles.editIcon} />
              </IconButton>
              <div>{row.gift_reciever_email}</div>
            </div>
          )}
        </TableCell>
        <TableCell>{row.months}</TableCell>
        <TableCell>{row.total_amount.toLocaleString()}</TableCell>
        <TableCell>
          <NotificationDateField
            disabled={row.email_sent}
            date={row.notification_date}
            onSave={handleSaveNotificationDate}
          />
        </TableCell>
        <TableCell>
          {row.email_sent ? (
            <DoneIcon style={styles.mainRowIcon} />
          ) : (
            <LoopIcon style={styles.mainRowIcon} />
          )}
        </TableCell>
        <TableCell>
          {row.onfleet_created ? (
            <DoneIcon style={styles.mainRowIcon} />
          ) : (
            <LoopIcon style={styles.mainRowIcon} />
          )}
        </TableCell>
        <TableCell>
          {row.flavour_selected ? (
            <DoneIcon style={styles.mainRowIcon} />
          ) : (
            <LoopIcon style={styles.mainRowIcon} />
          )}
        </TableCell>
        <TableCell>
          {row.fulfilment_status === "null" ? (
            <LoopIcon style={styles.mainRowIcon} />
          ) : (
            <DoneIcon style={styles.mainRowIcon} />
          )}
        </TableCell>
        <TableCell>
          <InstructionModal instruction={row.message} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={16}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                style={{ fontWeight: "bold", fontSize: 16 }}
              >
                Deliveries
              </Typography>
              <Table
                size="small"
                aria-label="purchases"
                style={{ width: "100%", tableLayout: "fixed" }}
                editable={{
                  onRowUpdate: () =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                      }, 600);
                    }),
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={classes.childRowIcon}
                      style={{ width: 65 }}
                    ></TableCell>
                    <TableCell style={styles.childRowHeader} width="150px">
                      Id
                    </TableCell>
                    <TableCell style={styles.childRowHeader} width="150px">
                      Date
                    </TableCell>
                    <TableCell style={styles.childRowHeader} width="200px">
                      Gift Receiver Name
                    </TableCell>

                    <TableCell style={styles.childRowHeader} width="200px">
                      Gift Receiver Phone
                    </TableCell>
                    <TableCell style={styles.childRowHeader} width="200px">
                      Gift Receiver Address
                    </TableCell>

                    <TableCell style={styles.childRowHeader} width="150px">
                      Item 1
                    </TableCell>
                    <TableCell style={styles.childRowHeader} width="150px">
                      Item 2
                    </TableCell>
                    <TableCell style={styles.childRowHeader}>
                      Personal Message
                    </TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.deliveries.map((delivery) => (
                    <ChildRow
                      key={delivery.delivery_id}
                      subscriberId={row.subscriber_id}
                      delivery={delivery}
                    ></ChildRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
