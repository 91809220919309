import React from 'react';
import SwapVertIcon from '@material-ui/icons/SwapVert';

import './FlavorPicker.css';
import FlavorItem from './FlavorItem';

const FlavorPicker = ({ availableFlavors, currentFlavorId, onUpdateFlavor, onClose }) => {
  const currentFlavor = availableFlavors.find(flavor => flavor.id === currentFlavorId);
  const otherFlavors = availableFlavors.filter(flavor => flavor.id !== currentFlavorId);

  const selectFlavor = (selectedFlavor) => {
    onUpdateFlavor(currentFlavorId, selectedFlavor);
  };

  return (
    <div className="flavor-picker__backdrop layout--centered" onClick={() => onClose()}>
      <div className="flavor-picker__wrapper">
        <div className="flavor-picker__current layout--centered">
          <FlavorItem flavor={currentFlavor} onSelectFlavor={selectFlavor} />
          <SwapVertIcon style={{ color: "var(--chaeban-primary)", fontSize: 30 }} />
        </div>
        <ul className="flavor-picker__list">
          {otherFlavors.map((flavor) => (
            <FlavorItem key={flavor.id} flavor={flavor} onSelectFlavor={selectFlavor} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FlavorPicker;
