

import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import axios from 'axios';

import './routes-info.styles.css'

let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
    link = `http://localhost:3000/`;
} else {
    env = url.split('.')[0];
    sec = env.split('//')[1];
    if (sec === 'secure2') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else if (sec === 'secure3') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else {
        link = `http://localhost:3000/`;
    }
}


const columns = [
    { field: 'col1', headerName: 'Group ID', width: 180, hide: true },
    { field: 'col2', headerName: 'Zone Name', width: 180, hide: true },
    { field: 'col3', headerName: 'Group Name', width: 300 },
    { field: 'col4', headerName: 'StartTime', width: 300 },
    { field: 'col5', headerName: 'EndTime', width: 300 },
    { field: 'col6', headerName: 'Capacity', width: 300 },
    { field: 'col7', headerName: 'Slots Available', width: 300 },
    { field: 'col8', headerName: 'Status', width: 300 },
    { field: 'col9', headerName: 'Zone ID', width: 180, hide: true },
];

let routesArray = []


const RoutesInfo = ({ zoneName, zoneId, selectedRoutes, setSelectedRoutes, history }) => {

    const [routesData, setRoutesData] = useState([])

    useEffect(() => {
        getRoutesData(zoneId)

    }, [])

    const getRoutesData = async (zoneId) => {
        const RoutesData = await axios.get(`${link}getGroup/${zoneId}`)
        setRoutesData(RoutesData.data.recordsets[0])
    }

    const rows = routesData.map((Data, i) => {
        return (
            { id: i, col1: Data["group_id"], col2: `Zone ${Data["zone_id"]}`, col3: Data["group_name"], col4: `${Data["group_start_time"].split("T")[0]} ${Data["group_start_time"].split("T")[1].toString().substring(0, 5)}`, col5: `${Data["group_end_time"].split("T")[0]} ${Data["group_end_time"].split("T")[1].toString().substring(0, 5)}`, col6: Data["group_capacity"], col7: Data["slots_available"], col8: Data["is_group_active"], col9: Data["zone_id"] }
        )
    })

    const getSelectedRoutes = () => {

        selectedRoutes.map((route) => {
            return routesArray.push(route.col1)
        })
    }




    const handleEditRoute = () => {
        history.push('/delivery/routes/editRoute')
    }

    const handleAddRoute = () => {
        history.push('/delivery/routes/addRoute')
    }

    const handleGetDeliveryData = () => {
        getSelectedRoutes()
        history.push('/delivery/routes/orders')

    }

    const handleHideGroup = async () => {
        const hideInfo = await axios.put(`${link}hideGroups`, {
            selectedRoutes
        })
        if (hideInfo.data == 'Error') {
            alert('Error in hiding routes. There might be some active routes in the selection')
        }
        getRoutesData(zoneId)

    }


    return (
        <div className='routes-page'>
            <div className='routes-page-text'>
                Selected Zone : {zoneName}
            </div>
            <div className='routes-table'>
                <div style={{ height: 400, width: '100%' }}>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                checkboxSelection
                                rows={rows}
                                columns={columns}
                                onSelectionModelChange={(ids) => {
                                    const selectedIDs = new Set(ids);
                                    const selectedRowData = rows.filter((row) =>
                                        selectedIDs.has(row.id)

                                    );
                                    setSelectedRoutes(selectedRowData)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='route-buttons'>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleEditRoute}>EDIT GROUP</Button>
                    <Button variant="contained" onClick={handleAddRoute}>Add Group</Button>
                    {selectedRoutes.length !== 0 ?
                        <>
                            <Button variant="contained" onClick={handleGetDeliveryData}>Get Delivery Data</Button>
                            <Button variant="contained" onClick={handleHideGroup}>Hide Group</Button>
                        </>
                        :
                        <>
                            <Button variant="contained" disabled onClick={handleGetDeliveryData}>Get Delivery Data</Button>
                            <Button variant="contained" disabled onClick={handleHideGroup}>Hide Group</Button>
                        </>
                    }
                </Stack>
            </div>



        </div>

    );
}

export default withRouter(RoutesInfo)