
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import axios from 'axios'



import './driver-assign-dropdown.styles.css'

let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
    link = `http://localhost:3000/`;
} else {
    env = url.split('.')[0];
    sec = env.split('//')[1];
    if (sec === 'secure2') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else if (sec === 'secure3') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else {
        link = `http://localhost:3000/`;
    }
}


let ordersArray = []
let routesIdArray = []
let emailDataArray =[]
let routesIdArrayforEmails =[]

const DriverAssignDropdown = ({ zoneName, selectedOrders, setSuccessAsignTask, setFailAssignTask, selectedRoutes }) => {




    useEffect(() => {
        getDriversList()

    }, [])

    const getDriversList = async () => {
        const drivers = await axios.get(`${link}driverList`)
        setDriversList(drivers.data.sort((a, b) => a.driverName.localeCompare(b.driverName)))

    }

    const [driversList, setDriversList] = useState([])
    const [selectedDriver, setSelectedDriver] = useState('')



    const handleDriverSelect = ({ target: { value } }) => {
        setSelectedDriver(value)


    }
    const handleAssignToDriver = async () => {

        getSelectedOnfleetIds()
        getRoutesIds()
        //send to onfleet with order numbers for driver allocation
        try {
            await axios.post(`${link}taskAssign`, {
                onfleetId: ordersArray,
                driverId: selectedDriver,
                routesIdArray
            })
            ordersArray = []
            routesIdArray = []
            setSuccessAsignTask(true)
        }
        catch {
            ordersArray = []
            routesIdArray = []
            setFailAssignTask(true)
        }

    }

    const sendDeliveryEmail = async () => {

        getEmailAndRouteData()
        getRoutesIdsforEmail()

        // getRoutesIds()
        //send to onfleet with order numbers for driver allocation
        try {
            await axios.post(`${link}sendDeliveryEmail`, {
                // onfleetId: ordersArray,
                driverId: selectedDriver,
                routesIdArrayforEmails,
                emailData : emailDataArray

            })
            ordersArray = []
            routesIdArray =[]
            emailDataArray =[]
            routesIdArrayforEmails =[]
            setSuccessAsignTask(true)
        }
        catch {
            ordersArray = []
            routesIdArray =[]
            emailDataArray = []
            routesIdArrayforEmails = []
            setFailAssignTask(true)
        }

    }

    const getEmailAndRouteData = () => {
        selectedOrders.map((data)=> {
            return emailDataArray.push({
                'customerName': data.col4,
                'email' : data.col12,
                'routeID' : data.col1,
                'giftSubsOrderNumber' : data.col13,
                'regularSubsOrderNumber' : data.col3,
                'customerID' : data.col14[0],
                'deliveryStatus' : data.col10
            })
        })
    }





    const getRoutesIds = () => {

        selectedRoutes.map((order) => {
            return routesIdArray.push(order.col1)
        })
    }

    const getRoutesIdsforEmail = () => {

        selectedRoutes.map((order) => {
           return routesIdArrayforEmails.push(order.col1)
        })
    }

    const getSelectedOnfleetIds = () => {

        selectedOrders.map((onfleetID) => {
            return ordersArray.push(onfleetID.col11)
        })
    }

    return (

        <div className='driver-assign-page'>

            <Box sx={{ width: 1 / 4 }}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Assign To</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedDriver}
                        label="Assign To"
                        onChange={handleDriverSelect}
                    >
                        {
                            driversList.map((driver, i) => {
                                return (
                                    <MenuItem key={i} value={driver['driverId']}>{driver['driverName']}</MenuItem>
                                )

                            })
                        }
                    </Select>
                </FormControl>

            </Box>
            {
                (selectedOrders.length !== 0 && selectedDriver ?
                    <div>
                    <Button type='submit' value="Submit" variant="contained" onClick={sendDeliveryEmail}>Send Emails</Button>
                    <Button type='submit' value="Submit" variant="contained" onClick={handleAssignToDriver}>Assign to Driver</Button>
                    </div>  :
                    <div>
                    <Button type='submit' value="Submit" variant="contained" disabled onClick={sendDeliveryEmail}>Send Emails</Button>
                    <Button type='submit' value="Submit" variant="contained" disabled onClick={handleAssignToDriver}>Assign to Driver</Button>
                    </div>



                )
            }



        </div>
    )
}


export default DriverAssignDropdown;
