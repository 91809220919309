export const DASHBOARD_MESSAGES = {
  Notification: {
    Error: "Error occured while sending notification ",
    NoSubscription: "No subscription to notify on selected subscriptions",
    Success: "Notification sent successfully ",
  },
  OnFleet: {
    Success: "Order(s) created successfully ",
    Error: "Error occured while creating orders ",
    NoSubscription:
      "No subscription to send in OnFleet on selected subscriptions ",
  },
  FetchData: {
    Subscriptions: {
      Error: "Error encountered while fetching subscriptions",
    },
    Deliveries: { Error: "Error encountered while fetching deliveries" },
  },
  Update: {
    Success: "Update successful",
    Error: "Update failed",
  },
  Products: {
    Error: "Error encountered while loading products",
  },
  Conman: {
    Success: "Order updated and successfully created on Onfleet",
    Failed: "Order could not be created in Onfleet. Reason updated.",
    Error: "Problem updating order",
  },
};

export const SNACKBAR_VARIANT = {
  Error: "error",
  Success: "success",
  Warning: "warning",
  Info: "info",
};

export const SELECT_FLAVOUR_MESSAGES = {
  Subscription: {
    Error: "Error loading subscription..",
  },
  Flavours: {
    Error: "Error encountered while loading flavours",
  },
  ErrorOnSubmitFlavours: "Error encountered while sending selected flavours", //TODO: RMS update this with polite and informative message
  FlavoursSent: "Flavours sent successfully", //TODO: RMS update this with polite and informative message
  InvalidLink: "We're Sorry, we can't find your subscription. ", //TODO: RMS update this with informative message ex. Please contact Chaeban Icecream with phone number
  expiredLink:
    "Link expired. If you have any questions, just send us an email at club@chaebanicecream.com and our support team will be happy to help. ", //TODO: RMS update this with informative message ex. Please contact Chaeban Icecream with phone number
};
