import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './delivery-zones-selections.styles.css'
import ZoneCardContainer from './ZoneCardContainer/zone-card-container.component'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import ChaebanLogo from '../ClubFlavorSelection/ChaebanLogo'
import ConfirmationPopup from '../ClubFlavorSelection/OrderConfimationPopup/order-confirmation.component'
import { ReactComponent as Loader } from '../../assets/loader.svg'
import { createBrowserHistory } from "history";




let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
    link = `http://localhost:4000/`;
} else {
    env = url.split('.')[0];
    sec = env.split('//')[1];
    if (sec === 'secure2') {
        link = `https://${sec}.chaebanicecream.com/public_api/`;
    } else if (sec === 'secure3') {
        link = `https://${sec}.chaebanicecream.com/public_api/`;
    } else {
        link = `http://localhost:4000/`;
    }
}






const DeliveryZonesSelection = () => {

    const location = useLocation();

    const {
        payload,
        subsription,
        deliveryRank,
        // customerId,
        otp,
        oid,
        otherFlavorInfo, pintsQty
    } = location.state


    let customerId = (window.sessionStorage.getItem('customerId'))

    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [customerAddress, setCustomerAddress] = useState("")
    const query = new URLSearchParams(location.search);
    const [subscriptionType, setSubscriptionType] = useState('')
    const [isEditable, setIsEditable] = useState('1')
    const [currentMonth, setCurrentMonth] = useState()
    const [orderId, setOrderId] = useState('')

    let { orderNumber } = useParams()
    const history = useHistory()
    // const history = createBrowserHistory();


    // let oid = orderNumber.split('?')[0]
    // let customerId = query.get("customerId") || (orderNumber.split('&')[0]).split('customerId=')[1]
    // let deliveryRank = query.get("deliveryRank")
    // var otp = query.get("otp")

    const [selectedZone, setSelectedZone] = useState("")
    const [selectedZoneId, setSelectedZoneId] = useState("")
    const [selectedRoute, setSelectedRoute] = useState("")
    const [routesAvailable, setRoutesAvailable] = useState([]) // should be filled with axio post below
    const [ZoneImages, setZoneImages] = useState([])
    const [isFlavPickerOpen, setIsFlavPickerOpen] = useState(false)
    const [cardClose, setCardStatus] = useState('1')
    const [deliveryHighlight, setDeliveryHighlight] = useState(false)

    const [orderConfirmation, setOrderConfirmation] = useState(0)
    const [isOrderSuccess, setIsOrderSuccess] = useState(true)
    const [isFlavSubmited, setIsFlavSubmited] = useState(true)
    const [isRoutesLoaded, setIsRoutesLoaded] = useState(false)

    const [currentPathname, setCurrentPathname] = useState(null)
    const [currentSearch, setCurrentSearch] = useState(null)


    const setUserSelectedZone = async (name, id) => {

        if (selectedZoneId !== id) {
            setSelectedZone(name)
            setSelectedZoneId(id)
            setSelectedRoute("")
            const routes = await axios.get(`${link}getGroup/${id}`)
            setRoutesAvailable(routes.data)
        }

    }

    const handleSelectRoute = ({ target: { value } }) => {
        setSelectedRoute(value)
    }

    /**
     * ABove is for deliveyr zone selector
     */

    useEffect(() => {
        getFlavors();
        getZones();


        if (deliveryRank === null) {
            setSubscriptionType('REGULAR')
        }
        else if (deliveryRank !== null) {
            setSubscriptionType('GIFT')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {


    //     history.listen((newLocation, action) => {
    //         if (action === "PUSH") {
    //             if (
    //                 newLocation.pathname !== currentPathname ||
    //                 newLocation.search !== currentSearch
    //             ) {
    //                 // Save new location
    //                 setCurrentPathname(newLocation.pathname);
    //                 setCurrentSearch(newLocation.search);

    //                 // Clone location object and push it to history
    //                 history.push({
    //                     pathname: newLocation.pathname,
    //                     search: newLocation.search
    //                 });
    //             }
    //         } else {
    //             // Send user back if they try to navigate back
    //             history.go(1);
    //         }
    //     })
    // }, [])


    useEffect(() => {
        unhighlightDeliverySelection()
    }, [selectedRoute])

    const unhighlightDeliverySelection = () => {
        if ((selectedRoute || selectedRoute === 0)) {
            setDeliveryHighlight(false)
        }
    }

    useEffect(() => {

        routesLoadedDisable()
    }, [customerAddress])

    const routesLoadedDisable = () => {

        if (customerAddress) {
            setIsRoutesLoaded(true)
        }
    }

    const getZones = async () => {
        const zonesData = await axios.get(`${link}getActiveZones`)
        setZoneImages(zonesData.data.recordset)
    }

    let response = []

    const getFlavors = async () => {

        setIsLoading(true);
        if (oid == undefined) {
            let oid = orderNumber.split('?')[0]
            setOrderId(oid)
            let customerId = (orderNumber.split('&')[0]).split('customerId=')[1] || (window.sessionStorage.getItem('customerId'))

            if ((orderNumber.split('&')[1])) {
                var otp = query.get("otp") || (orderNumber.split('&')[1]).split('otp=')[1]
            } else {

                var otp = true
            }

            response = await axios.get(`${link}clubUpdateFlavours/${oid}`, {
                params: {
                    customerId: customerId,
                    // currentMonth: currentMonth,
                    // isEditable: isEditable,
                    deliveryRank: deliveryRank,
                    otp: otp

                }

            });

        }

        else {

            response = await axios.get(`${link}clubUpdateFlavours/${oid}`, {
                params: {
                    customerId: customerId,
                    // currentMonth: currentMonth,
                    // isEditable: isEditable,
                    deliveryRank: deliveryRank,
                    otp: false

                }

            });

        }



        if (!response.data) {
            setIsLoaded(false);
        } else {

            if (!response.data.productDataAvailable) {
                setIsEditable(response.data.PintsInfo[0].isEditableStatus);
                setCardStatus(response.data.PintsInfo[0].isEditableStatus)
                setCurrentMonth(response.data.PintsInfo[0].currentMonth)
                setCustomerAddress(response.data.PintsInfo[0].customerAddress)
                setSelectedZoneId(response.data.PintsInfo[0].zone)
                setSelectedRoute(response.data.PintsInfo[0].group)
                if (response.data.PintsInfo[0].zone != null) {
                    const routes = await axios.get(`${link}getGroup/${response.data.PintsInfo[0].zone}`, {
                        params: {
                            selectedGroupId: response.data.PintsInfo[0].group
                        }
                    })
                    setRoutesAvailable(routes.data)
                }
                setIsLoaded(true);

            } else {
                setIsEditable(response.data.PintsInfo[0].isEditableStatus);
                setCardStatus(response.data.PintsInfo[0].isEditableStatus)
                setCurrentMonth(response.data.PintsInfo[0].currentMonth)
                setCustomerAddress(response.data.PintsInfo[0].customerAddress)
                setSelectedZoneId(response.data.PintsInfo[0].zone)
                setSelectedRoute(response.data.PintsInfo[0].group)
                if (response.data.PintsInfo[0].zone != null) {
                    const routes = await axios.get(`${link}getGroup/${response.data.PintsInfo[0].zone}`, {
                        params: {
                            selectedGroupId: response.data.PintsInfo[0].group
                        }
                    })
                    setRoutesAvailable(routes.data)
                }
                setIsLoaded(true);

            }


        }
        setIsLoading(false);
    };

    const handleIncompletedSubmit = async () => {
        setDeliveryHighlight(true)
        alert("Please select the delivery preference")
    }

    const handleSaveToLocal = () => {
        window.sessionStorage.setItem('zone', selectedZoneId)
        window.sessionStorage.setItem('group', selectedRoute)
        window.sessionStorage.setItem('payload', JSON.stringify(payload))
        window.sessionStorage.setItem('subsription', subsription)
        window.sessionStorage.setItem('deliveryRank', deliveryRank)
        window.sessionStorage.setItem('customerId', customerId)
        window.sessionStorage.setItem('otp', otp)
        window.sessionStorage.setItem('otherFlavorInfo', JSON.stringify(otherFlavorInfo))
        window.sessionStorage.setItem('routesAvailable', JSON.stringify(routesAvailable))
        window.sessionStorage.setItem('oid', oid)
        window.sessionStorage.setItem('pintsQty', pintsQty)
    }

    const handleDeliverySubmitOtp = async () => {

        try {
            setIsFlavSubmited(false)
            const payload = {};
            const res = await axios.put(`${link}updatedClubFlavors/${orderId}`, payload, {
                params: {
                    deliveryRank,
                    customerId,
                    zone: selectedZoneId,
                    group: selectedRoute,
                    otp: true,
                    pintsQty:pintsQty

                }
            });
            setIsFlavSubmited(true)
            setIsFlavPickerOpen(1)
            setOrderConfirmation(1)
            setIsOrderSuccess(true)

        }
        catch (err) {
            setIsFlavSubmited(true)
            setOrderConfirmation(1)
            setIsOrderSuccess(false)
        }

    }

    const handlePopupClose = () => {
        history.push(`/clubUpdateFlavours/${customerId}`)
        setOrderConfirmation(0)
    }

    const handleRouteToAddon = () => {
        history.push({
            pathname: `/clubUpdateFlavours/flavorSelection/${customerId}/addon`,
            state: {
                subsription,
                customerId
            }
        })
        setOrderConfirmation(0)

    }

    return (
        isEditable ?
            <div>
                {orderConfirmation == 1 ?
                    <ConfirmationPopup className='confirmation-popup' handlePopupClose={handlePopupClose} handleRouteToAddon={handleRouteToAddon} isOrderSuccess={isOrderSuccess} subsription={subsription} /> : null
                }
                {!isFlavSubmited || !isRoutesLoaded ?
                    <div className='loader-container'>
                        <Loader className="loader" />
                    </div> :
                    <div className='delivery-selection layout--centered'>
                        {location.state.oid ?
                            location.state.deliveryRank === null ?
                                <Link to={`/clubUpdateFlavours/flavorSelection/${oid}?customerId=${customerId}&otp=${otp}`} className="flavor-selection-list__back">
                                    {<div><ArrowBackIcon style={{ fontSize: 30 }} />
                                        <Typography variant="srOnly">Return to order list</Typography></div>}

                                </Link> :
                                <Link to={`/clubUpdateFlavours/flavorSelection/${oid}?customerId=${customerId}&deliveryRank=${deliveryRank}`} className="flavor-selection-list__back">
                                    {<div><ArrowBackIcon style={{ fontSize: 30 }} />
                                        <Typography variant="srOnly">Return to order list</Typography></div>}
                                </Link>
                            :
                            <Link to={`/clubUpdateFlavours/${customerId}`} className="flavor-selection-list__back">
                                {<div><ArrowBackIcon style={{ fontSize: 30 }} />
                                    <Typography variant="srOnly">Return to order list</Typography></div>}

                            </Link>}
                        <ChaebanLogo />
                        <ZoneCardContainer
                            ZoneImages={ZoneImages}
                            setUserSelectedZone={setUserSelectedZone}
                            selectedZone={selectedZone}
                            selectedZoneId={selectedZoneId}
                            routesAvailable={routesAvailable}
                            handleSelectRoute={handleSelectRoute}
                            selectedRoute={selectedRoute}
                            customerAddress={customerAddress}
                            isEditable={isEditable}
                            isFlavPickerOpen={isFlavPickerOpen}
                            deliveryHighlight={deliveryHighlight}
                        />
                        {(selectedRoute || selectedRoute === 0) ?
                            location.state.oid ?
                                <Link className='Next-Page-Button' to={{
                                    pathname: `/clubUpdateFlavours/flavorSelection/${customerId}/summary`,
                                    state: {
                                        zone: selectedZoneId,
                                        group: selectedRoute,
                                        payload,
                                        subsription,
                                        deliveryRank,
                                        customerId,
                                        otp,
                                        otherFlavorInfo,
                                        routesAvailable,
                                        oid, 
                                        pintsQty
                                    }
                                }} onClick={handleSaveToLocal}
                                >
                Order Summary ->
                                </Link> :
                                <button className='Next-Page-Button' onClick={handleDeliverySubmitOtp}>  Submit Delivery Preference!</button>
                            :
                            <button className='Next-Page-Button' onClick={handleIncompletedSubmit}>  Please select a route</button>


                        }

                    </div>

                }
            </div> : "GO TO SUMMARY PAGE"
    )
}

export default DeliveryZonesSelection;