import React, { createContext, useContext, useCallback, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import {
  DASHBOARD_MESSAGES,
  SELECT_FLAVOUR_MESSAGES,
  SNACKBAR_VARIANT,
} from "../components/Core/Constants";

const CryptoJS = require("crypto-js");
const decryptionKey = "nhoJDeploysyeRFESageBEetaKDDarryl";

const FlavourSelectionContext = createContext(undefined);

function FlavourSelectionProvider({ children }) {
  const [deliveries, setDeliveries] = useState([]);
  const [store, setStore] = useState({ status: "init" });

  const { enqueueSnackbar } = useSnackbar();

  const fetchSubscription = useCallback(
    async (token) => {
      setStore({ status: "loading" });
      const encoded = atob(token);
      const bytes = CryptoJS.AES.decrypt(encoded.toString(), decryptionKey);
      const subscriberId = bytes.toString(CryptoJS.enc.Utf8);

      const sortByDeliveryRank = (a, b) => {
        return b.delivery_rank - a.delivery_rank;
      };

      if (subscriberId) {
        const apiUrl = `/public_api/subscriptions/select_flavour/${subscriberId}`;

        try {
          const result = await axios.get(apiUrl);
          if (!result.data.onfleet_created) {
            const deliveries = result.data.deliveries;
            const sortedDeliveries = deliveries.sort(sortByDeliveryRank);
            let index = 0;

            sortedDeliveries.forEach((delivery) => {
              delivery.complete_month = moment()
                .add(index, "months")
                .format("MMM YYYY");
              index++;
            });

            setDeliveries(sortedDeliveries);
            setStore({ status: "loaded", data: sortedDeliveries });
          } else {
            setStore({ status: "error", error: "Token has expired" });
            enqueueSnackbar(SELECT_FLAVOUR_MESSAGES.expiredLink, {
              variant: SNACKBAR_VARIANT.Error,
              autoHideDuration: 30000,
            });
          }
        } catch (error) {
          setStore({ status: "error", error });
          enqueueSnackbar(DASHBOARD_MESSAGES.Products.Error, {
            variant: SNACKBAR_VARIANT.Error,
          });
        }
      } else {
        setStore({ status: "error", error: "Invalid token" });
        enqueueSnackbar(SELECT_FLAVOUR_MESSAGES.InvalidLink, {
          variant: SNACKBAR_VARIANT.Error,
        });
      }
    },
    [enqueueSnackbar]
  );

  const selectFlavour = useCallback(
    (deliveryId, isFirst, product) => {
      let updatedDeliveries = deliveries;
      const dIdx = updatedDeliveries.findIndex(
        (d) => d.delivery_id === deliveryId
      );
      if (dIdx > -1) {
        if (isFirst) {
          updatedDeliveries[dIdx].flavour1_name = product.title;
          updatedDeliveries[dIdx].flavour1_sku = product.variants[0].sku;
        } else {
          updatedDeliveries[dIdx].flavour2_name = product.title;
          updatedDeliveries[dIdx].flavour2_sku = product.variants[0].sku;
        }

        setDeliveries(updatedDeliveries);
        setStore((store) => {
          return { ...store, data: updatedDeliveries };
        });
      }
    },
    [deliveries]
  );

  const submitSelections = useCallback(async () => {
    const payload = [
      {
        shopify_id: parseInt(deliveries[0].shopify_id),
        subscriber_id: deliveries[0].subscriber_id,
        fromEmail: true,
        email: deliveries[0].gift_reciever_email,
        deliveries: deliveries.map((d) => ({
          delivery_id: parseInt(d.delivery_id),
          flavour1_sku: d.flavour1_sku,
          flavour1_name: d.flavour1_name,
          flavour2_sku: d.flavour2_sku,
          flavour2_name: d.flavour2_name,
        })),
      },
    ];
    try {
      await axios.put("/public_api/orders", payload);
      enqueueSnackbar(SELECT_FLAVOUR_MESSAGES.FlavoursSent, {
        variant: SNACKBAR_VARIANT.Success,
      });
      return true;
    } catch {
      enqueueSnackbar(SELECT_FLAVOUR_MESSAGES.ErrorOnSubmitFlavours, {
        variant: SNACKBAR_VARIANT.Error,
      });
      return false;
    }
  }, [enqueueSnackbar, deliveries]);

  const isAllSelected = useCallback(() => {
    return !deliveries.find((d) => !d.flavour1_sku || !d.flavour2_sku);
  }, [deliveries]);

  const mutators = {
    fetchSubscription,
    selectFlavour,
    isAllSelected,
    submitSelections,
  };

  return (
    <FlavourSelectionContext.Provider value={{ ...store, ...mutators }}>
      {children}
    </FlavourSelectionContext.Provider>
  );
}

function useFlavourSelection() {
  const context = useContext(FlavourSelectionContext);
  if (context === undefined) {
    throw new Error(
      "useFlavourSelection must be used within FlavourSelectionProvider"
    );
  }
  return context;
}

export { FlavourSelectionProvider, useFlavourSelection };
