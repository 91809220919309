import React, { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';

const PickerDateTimeEnd = ({ handleDateChangeEnd, endDate }) => {
    // const [value, onChange] = useState(new Date());
    return (
        <div>
            <DateTimePicker
                onChange={handleDateChangeEnd}
                value={new Date(endDate)}
            />
        </div>
    )


}


export default PickerDateTimeEnd;