import React from "react";
import { Tooltip, makeStyles, withStyles } from "@material-ui/core";
import { ArrowDropDownOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "1em",
    border: "none",
    borderBottom: "1px solid #ccc",
    padding: 0,
    "&:focus": {
      outline: "none",
    },
  },
  btnText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const FlavourToolTip = withStyles({
  tooltip: {
    fontSize: 18,
    width: 250,
  },
})(Tooltip);

export default function FlavourSelectButton({
  delivery,
  disabled,
  flavour,
  onClick,
}) {
  const classes = useStyles();
  const selected = delivery[`flavour${flavour}_name`];

  return (
    <div className={classes.root}>
      <FlavourToolTip title={selected || ""} placement="top-start">
        <button
          className={classes.button}
          onClick={onClick}
          disabled={disabled}
        >
          <span className={classes.btnText}>
            {selected || `Select Flavour ${flavour}`}
          </span>
          {!disabled && <ArrowDropDownOutlined />}
        </button>
      </FlavourToolTip>
    </div>
  );
}
