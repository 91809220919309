import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import {
  DASHBOARD_MESSAGES,
  SNACKBAR_VARIANT,
} from "../components/Core/Constants";

const ConmanContext = createContext(undefined);

function ConmanProvider({ children }) {
  const [store, setStore] = useState({ status: "init" });

  const { enqueueSnackbar } = useSnackbar();

  const fetchOrders = async (status) => {
    try {
      setStore({ status: "loading" });
      const result = await axios.get("/api/orders/" + status);
      setStore({
        status: "loaded",
        payload: result.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateOrder = async (payload) => {
    try {
      let result = await axios.patch("/api/orders", payload);
      if (result.data.status === "successful") {
        enqueueSnackbar(DASHBOARD_MESSAGES.Conman.Success, {
          variant: SNACKBAR_VARIANT.Success,
        });
      } else {
        enqueueSnackbar(DASHBOARD_MESSAGES.Conman.Failed, {
          variant: SNACKBAR_VARIANT.Error,
        });
      }
    } catch (err) {
      enqueueSnackbar(DASHBOARD_MESSAGES.Conman.Error, {
        variant: SNACKBAR_VARIANT.Error,
      });
      console.log(err);
    }
  };

  const mutators = { fetchOrders, updateOrder };

  return (
    <ConmanContext.Provider value={{ ...store, ...mutators }}>
      {children}
    </ConmanContext.Provider>
  );
}

function useConman() {
  const context = useContext(ConmanContext);
  if (context === undefined) {
    throw new Error("useConman must be used within ConmanProvider");
  }

  return context;
}

export { ConmanProvider, useConman };
