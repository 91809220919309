export function resizeImg(src) {
  const extRegex = /\.(gif|jpe?g|tiff?|png|webp|bmp)/i;
  let newString = src;
  const contains = extRegex.exec(newString);

  if (contains) {
    newString = newString.substr(0, contains.index) + '_260x' + newString.substr(contains.index);
  }

  return newString;
}
