
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import StartEndTimePage from '../StartEndTime/start-end-time.component';
import { withRouter } from 'react-router';
import axios from 'axios'
import moment from "moment";
import TextField from '@mui/material/TextField';



import './edit-route-selection.styles.css'
import { StarRate } from '@material-ui/icons';

// import RoutesTable from './availble-routes.component';
// import { Route, Switch, withRouter } from 'react-router';

let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
    link = `http://localhost:3000/`;
} else {
    env = url.split('.')[0];
    sec = env.split('//')[1];
    if (sec === 'secure2') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else if (sec === 'secure3') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else {
        link = `http://localhost:3000/`;
    }
}



const EditRouteSelection = ({ history, zoneId }) => {


    console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++", zoneId)
    useEffect(() => {
        getRoutesData(zoneId)


    }, [])

    const getRoutesData = async (zoneId) => {
        const RoutesData = await axios.get(`${link}getGroup/${zoneId}`)
        setRoutesData(RoutesData.data.recordsets[0])
    }

    const [selectedRouteToEdit, setSelectedRouteToEdit] = useState('')
    const [routeToEdit, setRouteToEdit] = useState('')
    const [startdate, setStartDate] = useState(new Date())
    const [enddate, setEndDate] = useState(new Date())
    const [routesData, setRoutesData] = useState([])
    const [routeID, setRouteID] = useState('')
    const [routeCapacity, setRouteCapacity] = useState('0')
    const [isRouteActive, setIsRouteActive] = useState(false)
    const [convertedEndDate, setConvertedEndDate] = useState(new Date())
    const [routeName, setRouteName] = useState('')



    const handleRouteEdit = ({ target: { value } }) => {
        setSelectedRouteToEdit('')
        setRouteToEdit(value)
        setSelectedRouteToEdit(value['group_name'])
        setRouteID(value['group_id'])
        setRouteCapacity(value.group_capacity)
        // setStartDate(moment((new Date(value.group_start_time)).getTime()).add({ hours: 6 }).toDate())
        setStartDate(moment((new Date(value.group_start_time)).getTime()).add({ hours: 5, minutes : 0 }).toDate())
        setEndDate(moment((new Date(value.group_end_time)).getTime()).add({ hours: 5, minutes : 0 }).toDate())

        if (value.is_group_active === '1') {
            setIsRouteActive(true)
        } else if (value.is_group_active === '0') {
            setIsRouteActive(false)
        }
    }

    const handleROuteActiveSelection = (event) => {
        setIsRouteActive(event.target.checked)

    }

    const selectStartDate = (event) => {
        setStartDate(event.toString())
    }

    const selectEndDate = (event) => {
        setEndDate(event.toString())
    }

    const handleCapacityEnter = (event) => {
        setRouteCapacity(event.target.value)
    }

    const handleRouteEditSubmit = async () => {

        await axios.put(`${link}editGroup`, {
            startdate: startdate,
            enddate: enddate,
            groupID: routeID,
            routeCapacity: routeCapacity,
            isRouteActive: isRouteActive,
            groupName: routeName  || routeToEdit.group_name

        })
        history.push('/delivery/routes')
    }

    const handleRouteNameChange = (event) => {
        setRouteName(event.target.value)
    }

    const label = "Update Route"

    return (
        <div className='edit-route-page'>
            <div className='edit-route-text'>
                Edit Route
            </div>
            <div className='edit-route-dropdown'>
                {
                    selectedRouteToEdit == '' ?

                        <Box sx={{ minWidth: 240 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Edit Route</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={routeToEdit}
                                    label="Edit Route"
                                    onChange={handleRouteEdit}
                                >
                                    {
                                        routesData.map((route, i) => {
                                            return (
                                                <MenuItem key={i} value={route}>{route['group_name']}</MenuItem>
                                            )

                                        })
                                    }
                                </Select>
                            </FormControl>

                        </Box> :
                        <div>
                            <div className='add-route-inputfield'>
                                Route Name
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <div>
                                        <TextField
                                            required
                                            type="text"
                                            id="RouteName"
                                            name="RouteName"
                                            label="RouteName"
                                            defaultValue={routeToEdit.group_name}
                                            onChange={handleRouteNameChange}
                                        />
                                    </div>
                                </Box>
                            </div>
                            <StartEndTimePage selectStartDate={selectStartDate} startdate={startdate} selectEndDate={selectEndDate} enddate={enddate} handleRouteSubmit={handleRouteEditSubmit} label={label} handleCapacityEnter={handleCapacityEnter} routeCapacity={routeCapacity} handleROuteActiveSelection={handleROuteActiveSelection} isRouteActive={isRouteActive} />
                        </div>
                }



            </div>
        </div>
    );
}


export default withRouter(EditRouteSelection);
