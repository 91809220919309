import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import moment from "moment";
import Eye from "@material-ui/icons/RemoveRedEye";
import { useHistory } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link
        target="_blank"
        color="inherit"
        href="https://www.chaebanicecream.com/"
      >
        Chaeban Ice Cream
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  invalidPassword: {
    color: "rgb(239 41 41 / 87%)",
    marginBottom: 10,
    display: "block",
  },
  hidden: {
    display: "none",
  },
  passwordWrapper: {
    position: "relative",
    display: "flex",
    marginBottom: 14,
  },
  eye: {
    position: "absolute",
    top: "38%",
    right: "5%",
    cursor: "pointer",
    hover: {
      color: "#00fcb6",
    },
  },
}));

export default function SignIn({ onLogIn }) {
  const classes = useStyles();
  let history = useHistory();

  //TODO: Placeholder for API calls
  /*
  const [state, setState] = useState({
    loading: true,
    user: null,
  });

  const url = "";
  const [page, setPage] = useState(1);
  useEffect(() => {
    fetch(url, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setState({ loading: false, user: response.results[0] });
        console.log(response.results[0]);
      })
      .catch((error) => console.log(error));
  }, [page]);
*/

  //TODO: RMS - get user info from API
  const encryptionKey = "09c75bda-3529-4cbe-a823-1dbb6827cd4f";

  const users = [
    {
      username: "Admin",
      password: "U2FsdGVkX1/zVtz6XaVmjuDcekJmYqkSlsZsugZnP04=",
    },
    {
      username: "Manager",
      password: "U2FsdGVkX1+RgZOyyfmdlMI+XknzDyQxoScJprEkKoM=",
    },
  ];

  const [logInButtonState, setLogInButtonState] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleUsernameChange = (event) => {
    setLogInButtonState(
      !(event.currentTarget.value && event.currentTarget.value.length >= 3)
    );
    setUsername(event.currentTarget.value);
    setInvalidPassword(false);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.currentTarget.value);
    setInvalidPassword(false);
  };

  const handleLogin = (event) => {
    const user = users.find((u) => u.username === username);
    if (user) {
      validatePassword(user, password, event);
    } else {
      showInvalidPassword(event);
    }
  };

  function showInvalidPassword(event) {
    setInvalidPassword(true);
    event.preventDefault();
  }

  //TODO:RMS - move to its own class
  const validatePassword = (user, password, event) => {
    const CryptoJS = require("crypto-js");

    const bytes = CryptoJS.AES.decrypt(user.password.toString(), encryptionKey);
    const decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);

    if (decryptedPassword === password) {
      const userContext = {
        username: user.username,
        isLoggedIn: true,
        expiration: moment(new Date()).add(12, "h"), //expired in 12hrs
      };
      sessionStorage.setItem("userContext", JSON.stringify(userContext));
      history.push("/");
      onLogIn();
    } else {
      showInvalidPassword(event);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Username or Email Address "
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleUsernameChange}
          />
          <div className={classes.passwordWrapper}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={passwordShown ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              onChange={handlePasswordChange}
            />
            <Eye
              className={classes.eye}
              style={{ color: passwordShown ? "black" : "grey" }}
              onClick={togglePasswordVisiblity}
            ></Eye>
          </div>
          <div
            className={classes.invalidPassword}
            style={{ display: invalidPassword ? "block" : "none" }}
          >
            Invalid username/password *
          </div>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={logInButtonState}
            onClick={handleLogin}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
