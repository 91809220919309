import './shopify-products-array.styles.css';
import Product from '../Product/shopify-product.component';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core';
import React, { useState } from 'react';

const ProductsArray = ({ products, client, addVariantToCart }) => {
  const [expanded, setExpanded] = React.useState('panel_0');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="Product-wrapper">
      {Object.keys(products).map((key, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel_${index}`}
          onChange={handleChange(`panel_${index}`)}
        >
          <AccordionSummary expandIcon="v" aria-controls={index} id={key}>
            <Typography>{key}</Typography>
          </AccordionSummary>
          <AccordionDetails className="details">
            {products[key].map((product) => (
              <Product
                product={product}
                client={client}
                key={product.id}
                addVariantToCart={addVariantToCart}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default ProductsArray;
