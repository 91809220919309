import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import axios from 'axios';
import SelectZone from './select-zones/zone-select.component'
import UnassignedOrders from './unassigned-orders/unassigned-orders.component'
// import CustomizedSnackbarsSuccess from '../../Snackbar/snackbar.component'
// import CustomizedSnackbarsFail from '../../Snackbar/snackbar-fail.component'


let link;
let env;
let sec;
let url;
let loc;

url = window.location.href;
loc = url.split('//')[1];

if (loc.includes('localhost')) {
    link = `http://localhost:3000/`;
} else {
    env = url.split('.')[0];
    sec = env.split('//')[1];
    if (sec === 'secure2') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else if (sec === 'secure3') {
        link = `https://${sec}.chaebanicecream.com/api/`;
    } else {
        link = `http://localhost:3000/`;
    }
}





const Unassigned = ({ history }) => {

    const [zoneName, setZoneName] = useState('');
    // const [zoneNameToEdit, setZoneNameToEdit] = useState('')
    const [zoneInfo, setZoneInfo] = useState([])
    const [zoneId, setZoneId] = useState("")
    // const [selectedRoutes, setSelectedRoutes] = useState([]);


    useEffect(() => {
        getAllZoneData();
    }, []);


    const getAllZoneData = async () => {
        const zoneData = await axios.get(`${link}getZones`)
        setZoneInfo(zoneData.data.recordset)
    }

    const handleSelectZone = ({ target: { value } }) => {

        if (value !== 'NULL') {
            setZoneName(value['zone_name'])
            setZoneId(value['zone_id'])
        } else if ((value === 'NULL')) {
            setZoneName('NO ZONE SELECTED')
            setZoneId(value)
        }
        history.push('/unassigned/orders')


    };

    // const handleZoneNameToEdit = ({ target: { value } }) => {
    //     setZoneNameToEdit(value)
    //     history.push('/delivery/editZone/Submit')
    // }


    return (
        <div>
            <Switch>
                <Route exact path='/unassigned' render={() => (<SelectZone handleSelectZone={handleSelectZone} zoneName={zoneName} ZoneData={zoneInfo} setZoneInfo={setZoneInfo} />)} />
                <Route exact path='/unassigned/orders' render={() => (<UnassignedOrders zoneName={zoneName} zoneId={zoneId} ZoneData={zoneInfo}/>)} />

            </Switch>
        </div>
    )
}

export default withRouter(Unassigned);

